import {AfterViewInit, Component, OnInit} from '@angular/core';
import {MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {H} from "../../../shared/helpers/H";
import {AppService} from "../../../shared/services/app.service";
import {ApiService} from "../../../shared/services/api.service";
import {SiteService} from "../../../shared/services/site.service";
import {K} from "../../../shared/models/K";
import {Pdm} from "../../../shared/models/Pdm";
import {PdmRegDef} from "../../../shared/models/PdmRegDef";
import {
    Action,
    ActionAssignee,
    ActionComment,
    ActionEconomyLegacy,
    ActionEconomyPerAgent, ActionLogItem,
    ActionSubvention
} from "../../../shared/models/Action.model";
import {EnergyStatsItem} from "../../../shared/models/EnergyStats.model";
import {ClientSite, SiteTariffConfig, StorageDocument, User} from "../../../shared/models/models";
import moment from "moment";
import 'moment/locale/de-ch';
import {Observable} from "rxjs";
import {diff, addedDiff, deletedDiff, updatedDiff, detailedDiff} from 'deep-object-diff';
import {forEach} from "lodash";
import {getStorage, ref, getDownloadURL, getMetadata} from "firebase/storage";

@Component({
    selector: 'app-bottom-sheet-action',
    templateUrl: './bs-action.component.html',
    styleUrls: ['./bs-action.component.scss']
})
export class BsActionComponent implements OnInit, AfterViewInit {

    public activeFieldsToggle = {};
    public K = K;

    dateProposed: Date;
    dateDue: Date;
    dateTerminated: Date;
    EnergyAgents: string;

    addEconomyFluid = "";
    addEconomyAmount = null;

    selectedPdmForEconomy: Pdm = null;
    selectedTariffKindForEconomy: string = '';
    selectedTariffForEconomy: number = 0;
    selectedTariffForEconomyStr: string = '';
    selectedFinalEconomy: number = 0;
    selectedFinalEconomyStr: string = '';
    selectedYearForEconomy: number = 0;

    selectedPdmTots = [];
    selectedPdmAvailableTariffs = [];
    allRegsForSite: PdmRegDef[] = [];

    assigneesFound: Map<string, ActionAssignee> = new Map<string, ActionAssignee>();
    assigneesNotFound: Map<string, ActionAssignee> = new Map<string, ActionAssignee>();

    temp_comment: string = '';
    docsMetas: any[] = [];
    actionCache: Action = null;

    constructor(
        public myapp: AppService,
        public siteService: SiteService,
        public api: ApiService,
        private _bottomSheetRef: MatBottomSheetRef<BsActionComponent>) {

        //moment.locale('fr-CH');
    }

    get act(): Action {
        return this.siteService.selectedAction;
    }

    get canEditRights() {
        const retVal = [];
        //ACTIONS: ['CREATE', 'EDIT_TEXT', 'EDIT_ECONOMY', 'COMMENT'],
        const CREATE = this.myapp.getIfUserCanDoForRoute('ACTIONS', 'CREATE');
        const EDIT_TEXT = this.myapp.getIfUserCanDoForRoute('ACTIONS', 'EDIT_TEXT');
        const EDIT_ECONOMY = this.myapp.getIfUserCanDoForRoute('ACTIONS', 'EDIT_ECONOMY');
        const COMMENT = this.myapp.getIfUserCanDoForRoute('ACTIONS', 'COMMENT');
        const ASSIGN_USER = this.myapp.getIfUserCanDoForRoute('ACTIONS', 'ASSIGN_USER');
        const UPLOAD = this.myapp.getIfUserCanDoForRoute('ACTIONS', 'UPLOAD');
        if (CREATE) retVal.push('CREATE');
        if (EDIT_ECONOMY) retVal.push('EDIT_ECONOMY');
        if (EDIT_TEXT) retVal.push('EDIT_TEXT');
        if (COMMENT) retVal.push('COMMENT');
        if (ASSIGN_USER) retVal.push('ASSIGN_USER');
        if (UPLOAD) retVal.push('UPLOAD');
        return retVal;
        //return CREATE || EDIT_ECONOMY || EDIT_TEXT || COMMENT;
    }

    ngOnInit(): void {
        // moment.locale('fr-CH');
        this.loadCurrentAction();

        if (this.siteService.selectedAction !== null) {
            this.initForm();
            // this.getAllRegs();
            if (!this.actionCache) {
                this.genActionCache();
            }
        }
        console.log("INIT", this.act.ts_proposed, this.dateProposed);
    }

    genActionCache() {
        const actRawJson = JSON.stringify(this.siteService.selectedAction);
        this.actionCache = new Action(JSON.parse(actRawJson));
        // console.log("CACHE BUILD", this.act, this.actionCache);
    }

    loadDocs() {
        new Observable((observer) => {
            this.siteService.loadDocuments(observer);
        }).subscribe(done => {
            this.docsMetas = [];
            if (!this.act.attachments) this.act.attachments = [];
            this.act.attachments.map(it => {
                this.getDocByPath(it)
            });
        })
    }

    loadCurrentAction() {

        if (this.act.ts_proposed)
            this.dateProposed = new Date(this.act.ts_proposed * 1000);
        if (this.act.ts_due)
            this.dateDue = new Date(this.act.ts_due * 1000);
        if (this.act.ts_completed > 0)
            this.dateTerminated = new Date(this.act.ts_completed * 1000);
        if (this.act.economies.length)
            this.recalc();

        this.loadDocs();
    }

    initForm() {
        this.act.investment_factor = this.act.investment_factor ? this.act.investment_factor : 100;

        this.recalc();
    }

    recalc() {
        if (this.act.status && this.act.status === 'COMPLETED') {
            if (!this.act.ts_completed) {
                this.dateTerminated = new Date();
                this.act.loadDatesFromForm(this.dateProposed,
                    this.dateDue,
                    this.dateTerminated);
            }
        } else {
            this.dateTerminated = null;
            this.act.ts_completed = null;
            this.act.loadDatesFromForm(this.dateProposed,
                this.dateDue,
                this.dateTerminated);
        }
        //console.log('recalc()', this.act);
        this.act.calcEconomyLegacy(this.siteService);
    }

    addLegacyEconomy(tariff: SiteTariffConfig) {
        if (!this.canEditRights.includes('EDIT_ECONOMY')) {
            this.myapp.showError("Droits manquants pour modifier l'économie");
            return;
        }
        const map = new Map<string, ActionEconomyPerAgent>;
        this.act.economies.forEach(t => map.set(t.uid_tariff, t));
        if (map.has(tariff.uid)) {
            map.get(tariff.uid).fluid = this.addEconomyFluid;
            this.myapp.confirm.confirm(
                {
                    title: "Confirmation de suppression",
                    message: "Voulez vous supprimer cette économie",
                }
            ).subscribe(ok => {
                console.log("addLegacyEconomy::CONFIRM DEL", ok, tariff)
                if (ok) {
                    map.delete(tariff.uid);
                    this.act.economies = Array.from(map.values());
                }
            })

        } else {
            const emptyEconomy = new ActionEconomyPerAgent();
            emptyEconomy.uid = H.randomStr(30);
            emptyEconomy.uid_tariff = tariff.uid;
            emptyEconomy.label = tariff.label;
            emptyEconomy.unit = tariff.unit;
            emptyEconomy.fluid = tariff.fluid;
            emptyEconomy.agent = tariff.agent;
            emptyEconomy.econEstimated = 0;
            emptyEconomy.econRealPerYear = {'none': 0};
            emptyEconomy.ts_created = H.unixTs();
            map.set(tariff.uid, emptyEconomy);
        }

        this.act.economies = Array.from(map.values());

        console.log("toggle econ", this.act.economies, tariff)
    }

    isEconomiesContainTariff(uidTariff) {
        if (this.act.economies)
            return this.act.economies.some(econ => {
                return (econ.uid_tariff === uidTariff);
            });
        return false;
    }

    /*
    ASSIGNEE
     */
    addAssignee(user: User) {
        if (this.getIfAssigneePresent(user.uid_bdd)) {
            this.act.assignees = this.act.assignees.filter(it => it.assignee !== user.uid_bdd);
        } else {
            const newAssignee = new ActionAssignee();
            newAssignee.populateFromUser(user);
            console.log("addAssignee", newAssignee);
            this.act.assignees.push(newAssignee);
        }
        this.saveAction();
    }

    getIfAssigneePresent(uid_bdd: string) {
        return this.act.assignees.find(it => it.assignee === uid_bdd);
    }

    getAssigneesUser(assignee: ActionAssignee): User {
        const userOfAssignee = this.siteService.responsiblesMapToOldUid.get(assignee.assignee);
        if (userOfAssignee) {
            assignee._user = userOfAssignee;
            this.assigneesFound.set(assignee.assignee, assignee);
        } else
            this.assigneesNotFound.set(assignee.assignee, assignee);
        return userOfAssignee;
    }

    /*
    SUBVENTIONS
     */

    addSubvention() {
        if (!this.act.subventions) this.act.subventions = [];
        const id = 'id' + H.randomInt(1000, 9999);
        const newSub = new ActionSubvention({id: id, label: "Subvention N°" + this.act.subventions.length, value: 0})
        this.act.subventions.push(newSub);
        console.log("this.addSubvention", this.act.subventions);
    }

    deleteSubvention(id) {
        this.act.subventions = this.act.subventions.filter(it => it.id !== id);
    }

    addComment() {
        const comm = new ActionComment(this.myapp.user, this.temp_comment);
        if (this.act.comments == '[]') this.act.comments = {};
        this.act.comments[comm.uid] = comm;
        this.temp_comment = '';
        this.saveAction();
    }

    publishAction() {
        this.act.ts_published = H.unixTs();
        this.saveAction();
    }

    checkDiffsAndPopulateLog() {
        const logItem = new ActionLogItem({});
        logItem.init(this.myapp.user);
        const keysToLog = ['priority', 'status', 'gc', 'title'];
        const diffs = updatedDiff(this.act, this.actionCache);
        Object.keys(diffs).forEach((field) => {
            if (keysToLog.includes(field) || field.includes('ts_') || field.includes('date')) {
                console.log("DIFF fields: ", field, diffs[field], '-->', this.act[field]);
                logItem.loadTrace(field, diffs[field], this.act[field]);
            }
        });
        return logItem;
    }

    saveAction() {
        this.act.loadDatesFromForm(this.dateProposed, this.dateDue, this.dateTerminated);
        this.siteService.getActionAvailableYearsPerAgentMetric();
        if (this.act.is_deleted) this.act.is_deleted = 1;
        else this.act.is_deleted = 0;
        if (this.act.gc) this.act.gc = 1;
        else this.act.gc = 0;
        if (this.act.is_ref) this.act.is_ref = 1;
        else this.act.is_ref = 0;
        //---------
        console.log("SaveAction", this.dateProposed, this.act.ts_proposed, this.act, this.act.ts_due, this.act.dateDue);
        const logItem = this.checkDiffsAndPopulateLog();

        if (logItem.traceCount > 0) {
            this.myapp.confirm.confirm({
                title: "Veuillez confirmer ces modifications?",
                message: 'Vous seriez noté comme auteur de ces modifications dans le système.' + logItem.logHtml
            }).subscribe(ok => {
                if (ok) {
                    this.act.log.push(logItem);
                    this._saveAction();
                }
            });
        } else {
            this._saveAction();
        }

    }

    _saveAction() {
        this.api.saveAction(this.act, this.act.uid).subscribe(resp => {
            console.log("SaveAction:resp", resp);
            if (resp.status === 1) {
                this.siteService.selectedAction = new Action(resp.body);
                const actionsMap = new Map<string, Action>();
                this.siteService.actions.forEach(act => actionsMap.set(act.uid, act));
                actionsMap.set(this.siteService.selectedAction.uid, this.siteService.selectedAction);
                this.siteService.actions = Array.from(actionsMap.values());
                this.loadCurrentAction();
                this.genActionCache();//regen cache
                this.siteService.siteSpecificEventTriggerer.next(SiteService.ACTION_SAVED);
                this.myapp.showMessage("Action sauvegardée avec succès!");
            } else {
                this.myapp.showError("Action non sauvegardé");
                console.log("Erreur sauvegarde action", resp.errors);
            }
        });
    }

    /*





    PDM related and unused code
     */
    ngAfterViewInit() {

    }

    getAllRegs() {
        this.siteService.api.getAllPdmsRegsForSite(this.siteService.uid).subscribe(resp => {
            resp.body.forEach(item => {
                this.allRegsForSite.push(new PdmRegDef(item));
            });
            console.log("getAllRegs", this.allRegsForSite);
        });

    }

    getDocByPath(path: string) {
        let fullPath = path;// open new path structure
        if (!fullPath.includes('document')) fullPath = 'attachments/' + path; // backward compatibility, fetch file from attachment storage folder

        const storage = getStorage();
        const docRef = ref(storage, fullPath);
        getMetadata(docRef).then(metas => {
            console.log("=======================getDocByUID:Metas", path, metas);
            const localMetas = {...metas};
            if (this.siteService.documentsMap.has(metas.name)) {

                console.log("=======================getDocByUID:Metas STORED", this.siteService.documentsMap.get(metas.name));
                localMetas['epDocUID'] = this.siteService.documentsMap.get(metas.name).uid;
                localMetas.name = this.siteService.documentsMap.get(metas.name).title;
            }
            localMetas['storedPath'] = path;
            this.docsMetas.push(localMetas);
        });
        // this.myapp.storage.ref(fullPath)
        //     .getMetadata()
        //     .subscribe(metas => {
        //         // console.log("getDocByUID:Metas", path, metas);
        //         if (this.siteService.documentsMap.has(metas.name)) {
        //             metas.epDocUID = this.siteService.documentsMap.get(metas.name).uid;
        //             metas.name = this.siteService.documentsMap.get(metas.name).title;
        //         }
        //         metas.storedPath = path;
        //         this.docsMetas.push(metas);
        //     });
        return this.siteService.documents.find(it => it.full_path.includes(path));
    }


    /*
    UTILS
     */
    openLink(metas) {
        console.log("openLink:Metas", metas);
        const mime = metas.contentType;
        if (mime.includes('pdf'))
            this.siteService.openAttachment(metas.fullPath);
        else {
            const docRef = ref(metas.fullPath);
            getDownloadURL(docRef).then((u) => {
                window.open(u, '_blank');
            });
        }
    }

    deleteDocument(docMeta: any) {
        this.myapp.confirm.confirm({
            title: "Confirmation de suppression",
            message: "Cette action est irréversible"
        }).subscribe(ok => {
            if (ok) this._deleteDocument(docMeta);
        });
    }

    _deleteDocument(docMeta: any) {
        console.log('deleteDocument 1', docMeta, this.act.attachments);
        if (this.siteService.documentsMap.has(docMeta.epDocUID)) {
            const epDocument = this.siteService.documentsMap.get(docMeta.epDocUID);

            console.log('deleteDocument 2', {stored: docMeta.storedPath, epUID: epDocument.uid, title: epDocument.title}, this.act.attachments);
            epDocument.uid_domain = this.siteService.uid;
            epDocument.removed = H.unixTs();
            this.api.saveDocument(epDocument).subscribe(resp => {
                console.log("delDocument", resp, this.act.attachments);
                this.act.attachments = this.act.attachments.filter(it => it !== docMeta.storedPath);
                this.saveAction();
            });
        } else {
            this.act.attachments = this.act.attachments.filter(it => it !== docMeta.storedPath);
            this.saveAction();
            console.log(this.siteService.documentsMap)
        }
    }

    cbFromUploader(event) {
        const {act, error, doc} = event;
        console.log("cbFromUploader", event);
        if (act === 'done') {
            this.act.attachments.push(doc.full_path);
            this.saveAction();
        }
    }

    /*
        getTariffValueForYear() {

            const consFieldForCost = this.selectedTariffKindForEconomy.replace('tTot', 'dTot');
            const costfFieldForCost = this.selectedTariffKindForEconomy.replace('tTot', 'cTot');
            const tariffObjForYear = this.selectedPdmForEconomy.summary.yearsTot.get(this.selectedYearForEconomy.toString());

            if (tariffObjForYear[consFieldForCost] !== undefined && tariffObjForYear[costfFieldForCost]) {
                this.selectedTariffForEconomy = tariffObjForYear[consFieldForCost].cumul / tariffObjForYear[costfFieldForCost].cumul;
                this.selectedTariffForEconomyStr = Number(this.selectedTariffForEconomy).toFixed(3) + " ct/KWh";
            } else {
                this.selectedTariffForEconomy = -1;
                this.selectedTariffForEconomyStr = "NC ct/KWh";
            }
            console.log("getTariffForYear", consFieldForCost, costfFieldForCost, tariffObjForYear, this.selectedTariffForEconomy, this.selectedTariffForEconomyStr);
        }

        get pdmsForfluid() {
            if (!this.addEconomyFluid) return [];
            return this.siteService.pdms.filter(pdm => pdm.fluid === this.addEconomyFluid);
        }

        legacyRemoveEconomy(econ) {
            // this.act.economies = this.act.economies.filter(it => it.agentMetric !== usage.agent_metric);
            this.siteService.selectedAction.economies = this.siteService.selectedAction.economies.filter(e => e !== econ) as [];
        }

        selectPdmForEconomy(pdm: Pdm) {
            this.selectedPdmForEconomy = pdm;
            this.selectedPdmAvailableTariffs = [];
            const tots = this.selectedPdmForEconomy.summary.totKinds;
            const availableTariffs = tots.filter(field => field[0] === 'c');

            availableTariffs.forEach(totKind => {
                const consFieldForCost = totKind.replace('cTot', 'dTot');
                const tariffFieldForCost = totKind.replace('cTot', 'tTot');
                console.log("selectYearForEconomy-foreach", consFieldForCost, tots, tots[consFieldForCost], tots instanceof Array);
                if (tots.includes(consFieldForCost))
                    this.selectedPdmAvailableTariffs.push(tariffFieldForCost);
            });
            console.log("selectYearForEconomy", this.selectedPdmForEconomy, tots, availableTariffs, this.selectedPdmAvailableTariffs);
        }
        */
}
