import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {Observable, of} from "rxjs";
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';

@Injectable()
// export class AuthGuard extends AngularFireAuthGuard {
export class AuthGuard implements CanActivate {

    constructor(
        public router: Router
    ) {
    }

    get isLoggedIn(): boolean {
        const token = JSON.parse(localStorage.getItem('loginToken') || '{}');
        return (token !== null);
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.isLoggedIn !== true) {
            // this.router.navigate(['home'])
        }
        return true;
    }
}
