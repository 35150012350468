import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from "@angular/core";
import {BaseComponent} from "../../shared/BaseComponent";
import {AppService} from "../../shared/services/app.service";
import {SiteService} from "../../shared/services/site.service";
import {PlotlyService} from "angular-plotly.js";
import {Action} from "../../shared/models/Action.model";
import {K} from "../../shared/models/K";

@Component({
    selector: 'bloc-actions-summary',
    template: `
        <mat-card class="p-16">
            <h2>
                Bilan des actions
            </h2>
            <div style="border-top: 1px solid #eee;" #plotDiv
                 class="card-body d-flex align-items-center justify-content-center">
                <!-- Plotly chart will be drawn inside this DIV -->
            </div>
            <table class="home-actions-summary">
                <tr>
                    <td class="col1">Total</td>
                    <td>{{total}}</td>
                    <td>100%</td>
                    <td>
                        <mat-icon>chevron_right</mat-icon>
                    </td>
                </tr>
                <tr>
                    <td class="col1">Terminées</td>
                    <td>{{totalCompleted}}</td>
                    <td>{{100 * totalCompleted / total|number:'0.0-0'}}%</td>
                    <td>
                        <mat-icon>chevron_right</mat-icon>
                    </td>
                </tr>
                <tr class="completed-row" *ngFor="let itemKV of mapCompletedStats|keyvalue">
                    <td class="col1">{{itemKV.key}}</td>
                    <td>{{itemKV.value}}</td>
                    <td>{{100 * itemKV.value / total|number:'0.0-0'}}%</td>
                    <td>
                        <mat-icon>chevron_right</mat-icon>
                    </td>
                </tr>
                <tr class="other-row" *ngFor="let itemKV of mapOtherStatusStats|keyvalue">
                    <td class="col1">{{itemKV.key}}</td>
                    <td>{{itemKV.value}}</td>
                    <td>{{100 * itemKV.value / total|number:'0.0-0'}}%</td>
                    <td style="width: 30px">
                        <mat-icon>chevron_right</mat-icon>
                    </td>
                </tr>
            </table>
        </mat-card>
    `,
    styleUrls: ['./site-home.component.scss']
})

export class BlocActionsSummaryComponent extends BaseComponent implements OnInit, OnChanges {
    @Input('actions') actions: Action[];
    @ViewChild('plotDiv', {static: true}) plotDiv: ElementRef<HTMLDivElement>;
    mapAll: Map<string, number> = new Map<string, number>();
    mapCompletedStats: Map<string, number> = new Map<string, number>();
    mapOtherStatusStats: Map<string, number> = new Map<string, number>();
    total = 0;
    totalCompleted = 0;
    data = [{
        values: [],
        labels: [],
        textposition: 'inside',
        textinfo: "label+percent",
        domain: {column: 0},
        name: 'Priorité',
        hoverinfo: 'label + percent+ name',
        hole: .3,
        type: 'pie',
        automargin: true
    }, {
        values: [],
        labels: [],
        textposition: 'inside',
        textinfo: "label+percent",
        domain: {column: 1},
        name: 'Statut',
        hoverinfo: 'label + percent + name',
        hole: .3,
        type: 'pie',
        automargin: true
    }];

    layout = {
        title: '',
        annotations: [
            {
                font: {
                    size: 12
                },
                showarrow: false,
                text: 'Priorité',
                x: 0.18,
                y: 0.5
            },
            {
                font: {
                    size: 12
                },
                showarrow: false,
                text: 'Statut',
                x: 0.81,
                y: 0.5
            }
        ],
        displaylogo: false,
        height: 250,
        width: 500,
        showlegend: false,
        margin: {"t": 0, "b": 0, "l": 20, "r": 20},
        grid: {rows: 1, columns: 2}
    };

    constructor(public myapp: AppService, public site: SiteService, public plotly: PlotlyService) {
        super();
    }

    ngOnInit() {
    }

    bootstrap() {
        this.total = 0;
        this.totalCompleted = 0;

        this.mapAll = new Map<string, number>();
        this.mapCompletedStats = new Map<string, number>();
        this.mapOtherStatusStats = new Map<string, number>();

        this.site.actions.forEach(act => {
            const priorityLabel = K.PriorityDisplayName[act.priority];
            const statusLabel = K.StatusDisplayName[act.status];
            if (act.is_deleted === 1) return;
            if (act.ts_published === 0) return;
            if (act.status !== "REFUSED")
                this.total++;
            if (act.status === "COMPLETED")
                this.totalCompleted++;

            if (this.mapCompletedStats.has(priorityLabel))
                this.mapCompletedStats.set(priorityLabel, this.mapCompletedStats.get(priorityLabel) + 1);
            else this.mapCompletedStats.set(priorityLabel, 1);

            if (this.mapOtherStatusStats.has(statusLabel))
                this.mapOtherStatusStats.set(statusLabel, this.mapOtherStatusStats.get(statusLabel) + 1);
            else
                this.mapOtherStatusStats.set(statusLabel, 1);
        });
        // console.log('this.mapCompletedStats', this.mapCompletedStats, this.mapOtherStatusStats);
        this.data[0].values = [];
        this.data[0].labels = [];
        this.data[1].values = [];
        this.data[1].labels = [];
        this.mapCompletedStats.forEach((v, k) => {
            this.data[0].values.push(v);
            this.data[0].labels.push(k);
        });
        this.mapOtherStatusStats.forEach((v, k) => {
            this.data[1].values.push(v);
            this.data[1].labels.push(k);
        });
        this.plotly.newPlot(this.plotDiv.nativeElement, this.data, this.layout, K.defaultConfig);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.bootstrap();
    }
}
