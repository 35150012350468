import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ReportSuivi} from 'app/shared/models/Suivi.model';
import {AppService} from "../../../shared/services/app.service";
import {SiteService} from "../../../shared/services/site.service";
import {CdkTextareaAutosize} from "@angular/cdk/text-field";
import {H} from "../../../shared/helpers/H";
import {Observable} from "rxjs";

@Component({
    selector: 'suivi-general-infos',
    template: `
        <h3 class="color-blue" fxLayout="row" fxLayoutAlign="space-between center">
            <!--<span>Informations générales</span>-->
            <div *ngIf="mode==='edit'" fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                    <mat-icon>import_export</mat-icon>
                    Cloner depuis:
                </div>
                <ng-container *ngFor="let rr of site.reportsItems">
                    <button class="btn-xs mr-4" *ngIf="rr.year!==report.year"
                            (click)="importFromRef(rr)" (dblclick)="importFromRef(rr,true)" mat-raised-button>
                        {{ rr.year }}
                    </button>
                </ng-container>

            </div>
            <span fxFlex></span>

            <button (click)="toggleEditMode()" *ngIf="mode==='view'" mat-icon-button>
                <mat-icon>edit</mat-icon>
            </button>
            <button (click)="toggleEditMode()" *ngIf="mode==='edit'" mat-icon-button>
                <mat-icon>cancel</mat-icon>
            </button>

            <ng-container *ngIf="initialMD5!==actualMD5">
                <button (click)="saveInfos()" mat-icon-button>
                    <mat-icon>save</mat-icon>
                </button>
            </ng-container>

        </h3>

        <table class="table-general-infos">
            <tr class="bloc-infos-item">
                <td class="item-title">Année de référence</td>
                <td *ngIf="mode==='view'||!(isRef&&reportsCount===1)">{{ reportRef.year }}</td>
                <td *ngIf="mode==='edit'&&isRef&&reportsCount===1">
                    <input type="number" class="goal-input"
                           (keyup)="editInfosOnKeyUp();"
                           [(ngModel)]="reportRef.year" placeholder="Année Réf"/>
                    <div class="noprint  warning-read-only">
                        Champs invariable dès la création du premier suivi
                    </div>
                </td>
            </tr>
            <tr class="bloc-infos-item">
                <td class="item-title">Année de début du suivi
                </td>
                <td *ngIf="mode==='view'||!(isRef&&reportsCount===1)">{{ reportRef.year_start }}</td>
                <td *ngIf="mode==='edit'&&isRef&&reportsCount===1">
                    <input type="number" class="goal-input"
                           (keyup)="editInfosOnKeyUp();"
                           [(ngModel)]="reportRef.year_start" placeholder="Année du début"/>

                    <div class="noprint   warning-read-only">
                        Champs invariable dès la création du premier suivi
                    </div>
                </td>
            </tr>
            <tr class="bloc-infos-item" *ngIf="isRef">
                <td class="item-title">Type d'engagement</td>
                <td>{{ myapp.k.TypeEngagementLabel[site.clientSite.type_suivi] }}</td>
            </tr>
            <tr class="bloc-infos-item" *ngIf="!isRef">
                <td class="item-title">Année de suivi</td>
                <td>{{ report.year }}</td>
            </tr>
            <tr class="bloc-infos-item" *ngFor="let field of fields">
                <td class="item-title">{{ reportRef.infos.labelForField(field) }}</td>
                <td *ngIf="mode==='view'" [innerHTML]="getValueForField(field)|nl2br"></td>
                <td *ngIf="mode==='edit'">
                    <ng-container *ngIf="isRef">
                        <textarea class="mono" *ngIf="getFieldType(field).type==='textarea'"
                                  (keyup)="editInfosOnKeyUp();"
                                  #autosize cdkTextareaAutosize="true" [cdkAutosizeMinRows]="0"
                                  [(ngModel)]="reportRef.infos[field]"></textarea>
                        <input class="mono" *ngIf="getFieldType(field).type==='input'"
                               (keyup)="editInfosOnKeyUp()"
                               [(ngModel)]="reportRef.infos[field]"/>
                        <select class="mono" *ngIf="getFieldType(field).type==='select'"
                                (change)="editInfosOnKeyUp()"
                                [(ngModel)]="reportRef.infos[field]">
                            <option [value]="opt" *ngFor="let opt of getFieldType(field).values">
                                {{ myapp.k.TypeEngagementLabel[opt] }}
                            </option>
                        </select>
                    </ng-container>

                    <textarea *ngIf="!isRef" class="mono"
                              #autosize cdkTextareaAutosize="true" [cdkAutosizeMinRows]="0"
                              (keyup)="editInfosOnKeyUp();"
                              [(ngModel)]="report.infos[field]"></textarea>
                </td>
            </tr>
        </table>
    `,
    styleUrls: ['./suivi.component.scss']
})
export class SuiviGeneralInfosComponent implements OnInit {
    @ViewChild("autosize") autosize: CdkTextareaAutosize;
    @Input('reportRef') reportRef: ReportSuivi;
    @Input('report') report: ReportSuivi;
    @Input('isRef') isRef: boolean;
    @Input('year') year: number;
    @Input('reportsCount') reportsCount: number;
    @Input('formType') formType: string;//ref iteratif

    mode = 'view';// or edit
    legacyMD5 = "";
    initialMD5 = "";
    actualMD5 = "";

    get fields() {
        if (this.isRef) return this.reportRef.getInfoFields();
        else return this.report.getInfoFields();
    }

    getFieldType(field): { type: string, values: string[] } {
        let retVal;
        const typeInput = this.reportRef.infos.suiviRefFields[field];
        if (typeInput.includes('select')) {
            let values = typeInput.split(':');
            values = values[1].split(',');
            retVal = {type: 'select', values};
        } else retVal = {type: typeInput, values: null};
        return retVal;
    }

    constructor(public myapp: AppService, public site: SiteService) {
    }

    ngOnInit(): void {
        if (!this.isRef) {
            this.report.infos.year = this.report.year;
            this.report.infos.year_start = this.report.year_start;
            this.actualMD5 = H.getMd5(JSON.stringify(this.report.infos));
            this.initialMD5 = H.getMd5(JSON.stringify(this.report.infos));
        } else {
            this.reportRef.infos.year = this.reportRef.year;
            this.reportRef.infos.year_start = this.reportRef.year_start;
            this.actualMD5 = H.getMd5(JSON.stringify(this.reportRef.infos));
            this.initialMD5 = H.getMd5(JSON.stringify(this.reportRef.infos));
        }
    }

    importFromRef(reeportToClone: ReportSuivi, force = false) {
        this.fields.forEach(field => {
            const val = this.report.infos[field];
            const newval = reeportToClone.infos[field];
            if ((!val || force) && newval) {
                this.report.infos[field] = reeportToClone.infos[field];
            }
        });
        this.editInfosOnKeyUp();
        // console.log('importFromRef', this.reportRef.infos, this.report.infos);
    }

    toggleEditMode() {
        if (this.mode === 'view') this.mode = 'edit';
        else this.mode = 'view';
    }

    getValueForField(field): string {
        let retVal = undefined;
        if (!this.isRef) {
            retVal = this.report.infos[field];
        } else retVal = this.reportRef.infos[field];
        return retVal;
    }

    editInfosOnKeyUp() {
        if (!this.isRef) {
            this.report.infos.year = this.report.year;
            this.report.infos.year_start = this.report.year_start;
            this.actualMD5 = H.getMd5(JSON.stringify(this.report.infos));
        } else {
            this.reportRef.infos.year = this.reportRef.year;
            this.reportRef.infos.year_start = this.reportRef.year_start;
            this.actualMD5 = H.getMd5(JSON.stringify(this.reportRef.infos));
        }
        // console.log('editInfosOnKeyUp', this.reportRef.infos, this.report.infos);
    }

    saveInfos() {
        const dataToDave = {};
        let req: Observable<any> = null;
        if (this.isRef) {
            this.reportRef.infos.year = this.reportRef.year;
            this.reportRef.infos.year_start = this.reportRef.year_start;
            this.initialMD5 = H.getMd5(JSON.stringify(this.reportRef.infos));
            console.log("saveInfos", this.reportRef.infos);
            dataToDave['infos'] = this.reportRef.infos;
            dataToDave['year'] = this.reportRef.year;
            dataToDave['year_start'] = this.reportRef.year_start;
            req = this.site.api.saveSuivi(this.reportRef, dataToDave);
        } else {
            this.report.infos.year = this.report.year;
            this.report.infos.year_start = this.report.year_start;
            this.initialMD5 = H.getMd5(JSON.stringify(this.report.infos));
            console.log("saveInfos", this.report.infos);
            dataToDave['infos'] = this.report.infos;
            dataToDave['year'] = this.report.year;
            dataToDave['year_start'] = this.report.year_start;
            req = this.site.api.saveSuivi(this.report, dataToDave);
        }
        req.subscribe(resp => {
            if (resp.status == 1) {
                this.myapp.showMessage("Suivi sauvegardé.");
                this.mode = 'view';
                // update hidden values
                if (this.isRef) {
                    dataToDave['year'] = this.reportRef.year;
                    this.site.api.saveSuivi(this.report, dataToDave).subscribe(respInner => {
                        console.log("saveInfos() Suivi-hidden inner", respInner);
                    })
                }
            }
            console.log("saveInfos() ", this.isRef, this.isRef ? this.reportRef : this.report, resp);
        });
    }

}
