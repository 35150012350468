import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import * as Highcharts from 'highcharts';
import PatternFill from "highcharts/modules/pattern-fill";
import Exporting from "highcharts/modules/exporting";
import Annotations from "highcharts/modules/annotations";
import Debugger from "highcharts/modules/debugger";

if (typeof Highcharts === 'object') {
    PatternFill(Highcharts);
    Exporting(Highcharts);
    Annotations(Highcharts);
    Debugger(Highcharts);
}
import {H} from "../../shared/helpers/H";
import {SiteService} from "../../shared/services/site.service";
import {Plot, PlotUnit} from "../../shared/models/models";
import {BaseComponent} from "../../shared/BaseComponent";
import {AppService} from "../../shared/services/app.service";
import {AxisPlotLinesOptions, Chart} from "highcharts";
import {K} from "../../shared/models/K";
import {OptimiseChart} from "../../shared/helpers/OptimiseChart";
import {BehaviorSubject, from, Observable, Subscription} from "rxjs";
import {Optimise} from "../../shared/helpers/Optimise";
import {concatMap} from "rxjs/operators";
import {Pdm} from "../../shared/models/Pdm";
import {PdmSignature} from "../../shared/models/PdmSignature";
import {PdmYearlyConfig, PdmYearModel} from "../../shared/models/PdmYearConfig";

@Component({
    selector: 'statmens-cassure',
    template: `
        <div fxLayout="column">
            <div fxLayout="row">
                <div fxFlex="750px">
                    <highcharts-chart [Highcharts]="Highcharts" *ngIf="chartOptions"
                                      id="cassure-finder-plot" [options]="chartOptions"
                                      (chartInstance)="onChartInstance($event)">
                    </highcharts-chart>
                </div>
                <table fxFlex="560px" class="table-values" *ngIf="tempSignature&&tempSignature.points&&showTable">
                    <tr (click)="myapp.showConsole(tempSignature)">
                        <td></td>
                        <td>ind</td>
                        <td>X</td>
                        <td>Y</td>
                        <td>Tg</td>
                        <td>Td</td>
                        <td>tTot</td>
                        <td>penteG</td>
                        <td>consG</td>
                        <td>penteD</td>
                        <td>consD</td>
                        <td>ecart</td>
                        <td>Conn</td>
                    </tr>
                    <tr *ngFor="let pt of tempSignature.points;let i=index;">
                        <td>{{ i + 1 }}</td>
                        <td (click)="tempSignature.sortBy('index')">{{ pt.index }}</td>
                        <td [class.inf]="pt.x<pt.connexion"
                            (click)="tempSignature.sortBy('x')">{{ pt.x|number:'1.2-2' }}
                        </td>
                        <td (click)="tempSignature.sortBy('y')">{{ pt.y|number:'1.2-2' }}</td>
                        <td>{{ pt.tGauche|number:'1.2-2' }}</td>
                        <td>{{ pt.tDroite|number:'1.2-2' }}</td>
                        <td (click)="tempSignature.sortBy('tTotal')">{{ pt.tTotal|number:'1.2-2' }}</td>
                        <td>{{ pt.leftSlope|number:'1.2-2' }}</td>
                        <td>{{ pt.leftConstant|number:'1.1-1' }}</td>
                        <td>{{ pt.rightSlope|number:'1.1-1' }}</td>
                        <td>{{ pt.rightConstant|number:'1.2-2' }}</td>
                        <td (click)="tempSignature.sortBy('ecart')">{{ pt.ecart|number:'1.2-2' }}</td>
                        <td (click)="tempSignature.sortBy('connexion')">{{ pt.connexion|number:'1.2-2' }}</td>
                    </tr>
                </table>
                <div fxFlex="360px">
                    <div fxLayout="column">
                        <div class="config-panel" *ngIf="tempSignature">
                            <h2> Valeur: {{ tempSignature.cassure|number }}</h2>
                            <h3 style="margin: 10px 0 4px 0">Options </h3>
                            <mat-checkbox (change)="saveSignatureServer()" class="mr-16" [(ngModel)]="tempSignature.sd">
                                Saut discret
                            </mat-checkbox>

                            <div fxLayout="row" class="p-4 cassure-input">
                                <input matInput placeholder="Cassure manuelle" type="number" step="0.1"
                                       [(ngModel)]="tempSignature.manual" (keyup.enter)="saveSignatureServer()">
                                <button (click)="manualValueClear()">Del</button>
                            </div>
                            <h3 style="margin: 10px 0 4px 0">Plat </h3>
                            <mat-radio-group [(ngModel)]="tempSignature.flat" (change)="saveSignatureServer()">
                                <mat-radio-button class="mr-8" [value]="'NONE'"> Normal</mat-radio-button>
                                <mat-radio-button class="mr-8" [value]="'LEFT'"> Gauche</mat-radio-button>
                                <mat-radio-button class="mr-8" [value]="'RIGHT'"> Droite</mat-radio-button>
                            </mat-radio-group>

                            <h3 style="margin: 10px 0 4px 0">Type de cassure</h3>
                            <mat-radio-group [(ngModel)]="tempSignature.type" (change)="saveSignatureServer()">
                                <mat-radio-button class="mr-8" [value]="'CHAUD'"> Chaud</mat-radio-button>
                                <mat-radio-button class="mr-8" [value]="'FROID'"> Froid</mat-radio-button>
                                <mat-radio-button class="mr-8" [value]="'MIXTE'"> Mixte</mat-radio-button>
                            </mat-radio-group>
                            <div fxLayout="row" class="p-5">
                                <button mat-raised-button color="primary" class="btn-xs-25 mr-4" (click)="saveSignatureServer(true)">
                                    Save
                                </button>
                                <button mat-raised-button color="primary" class="btn-xs-25 mr-4" (click)="tempSignature.calc()">
                                    Render
                                </button>
                                <button mat-raised-button color="primary" class="btn-xs-25 mr-4" (click)="showTable=!showTable">
                                    Table {{ showTable }}
                                </button>
                            </div>

                            <div fxLayout="row" *ngIf="tempSignature&&tempSignature.calc_cache" class="pt-8">
                                <button mat-raised-button *ngFor="let kv of tempSignature.calc_cache|keyvalue;" class="mr-4 p-4 btn-xs-25"
                                        [class.active]="site.selectedConfigForPdmAndYear.cassure_chosen_reg_index==kv.key"
                                        (click)="drawSignatureByIndex(kv.key)">{{ kv.key }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,

    styleUrls: ['../../shared/components/shared-styles/shared.scss']
})
export class StatmensCassureComponent extends BaseComponent implements OnInit, OnChanges {
    @Input('plot') plot: Plot;
    @Input('pdm') dataPdm: Pdm;
    @Input('year') year: number;

    chart: Chart;
    errors: any[] = [];
    Highcharts: typeof Highcharts = Highcharts;
    public chartOptions: Highcharts.Options;
    yMin = 600000;
    public tempSignature: PdmSignature = null;
    serieLines: any[] = [];
    consUnit = 'kWh';
    showTable = false;

    constructor(public myapp: AppService, public site: SiteService,) {
        super();
    }

    ngOnInit() {
        this.site.siteSpecificEventTriggerer.subscribe(event => {
            //  if (event === SiteService.STATMENS_CONFIG_RELOADED) {
            if (event === SiteService.PDMS_DATA_POPULATED && this.site.refYear) {
                if (this.dataPdm && this.chart) {
                    this.bootstrap();
                }
            }
            if (event === SiteService.PDMS_YEARLY_CONFIG_CHANGED) {
                this.bootstrap('siteSpecificEventTriggerer:PDMS_YEARLY_CONFIG_CHANGED');
            }
        });

        this.site.loadStatmensConfigs();
    }

    bootstrap(debugCalledBy = "") {
        if (!this.dataPdm) {
            console.error('StatmensCassureComponent:renderChart', 'dataPdm EMPTY');
            return;
        }
        // this.optimiseChart = new OptimiseChart(this.plot, this.site.selectedPdm, this.site.dataSummaryMap);
        // this.optimiseChart.applyForCassure(this); // generate this.chartOption according to plot and call chartUpdate

        this.tempSignature = new PdmSignature(this.site.year, this.dataPdm, this.site.selectedPdmYearlyPlotDataMap, this.site.selectedConfigForPdmAndYear);

        if (this.site.selectedConfigForPdmAndYear.cassure_chosen_reg_index)
            this.drawSignatureByIndex(this.site.selectedConfigForPdmAndYear.cassure_chosen_reg_index);
    }

    drawSignatureByIndex(index) {
        const calcDone = this.tempSignature.calc();
        if (!calcDone) {
            console.error("drawSignatureByIndex", index, "Not enough points");
            return;
        }

        // console.log("drawSignatureByIndex", index, this.tempSignature.calc_cache, this.tempSignature.calc_cache[index].plot)
        this.serieLines = this.tempSignature.calc_cache[index].plot.map(pt => {
            pt['y'] = pt['y'];// * this.plot.getUnitFactor();
            return pt;
        });
        this.tempSignature.cassure = this.tempSignature.calc_cache[index].pt.connexion;
        this.site.selectedConfigForPdmAndYear.cassure_chosen_reg_index = Number(index);
        this.updateChart();
        // this.chartUpdateSerie(1, serieLines);
    }

    updateChart() {
        let minX = 1000, maxX = -5;
        this.tempSignature.points.forEach(pt => {
            if (pt.x < minX) minX = pt.x;
            if (pt.x > maxX) maxX = pt.x;
        });
        // console.log("UpdateChart", this.tempSignature.points, this.serieLines, minX, maxX);
        this.chartOptions = {
            chart: {
                displayErrors: true,
                plotBackgroundColor: '#ffffff',
                plotShadow: false,
                borderColor: '#ff0000',
                borderWidth: 0,
                plotBorderWidth: K.lineWidthBase,
                reflow: false,
                width: 750,
                height: 500,
                marginTop: 40,
                marginRight: 50,
                style: {
                    fontFamily: "Roboto",
                    fontSize: "16px"
                }
            },
            legend: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            accessibility: {
                enabled: false
            },
            lang: {
                thousandsSep: "'",
                numericSymbols: null,
            },
            xAxis: {
                lineWidth: 2,
                lineColor: '#000000',
                gridLineColor: "#000000",
                gridLineWidth: 0.2,
                tickInterval: 5,
                crosshair: true,
                min: minX,
                max: maxX,
                startOnTick: false,
                endOnTick: false,
                labels: {
                    style: {
                        color: '#000000',
                        fontWeight: "bold",
                        fontSize: '14px',
                    }
                },
                title: {
                    text: "Température " + this.site.clientSite.weather,
                    style: {
                        color: '#000000',
                        fontWeight: "bold",
                        fontSize: '14px',
                    }
                }
            },
            yAxis: {
                lineWidth: 2,
                lineColor: '#000000',
                title: {
                    text: this.consUnit,
                    style: {
                        color: '#000000',
                        fontWeight: "bold",
                        fontSize: '14px',
                    }
                }
            },
            title: {
                text: undefined
            },
            plotOptions: {
                line: {
                    lineWidth: 10,
                    color: "#f00"
                },
                scatter: {
                    marker: {
                        radius: 2.5,
                        symbol: 'circle',
                    },
                    jitter: {
                        x: 0
                    }
                }
            },
            series: [
                {
                    dataLabels: {
                        useHTML: true,
                        enabled: true,
                        inside: true,
                        x: 1,
                        y: 10,
                        align: "left",
                        position: "right",
                        borderWidth: 0,
                        allowOverlap: true,
                        zIndex: 1,
                        backgroundColor: "#fff",
                        style: {fontWeight: "normal", fontSize: '12px'},
                        format: "[{point.index}]",
                    },
                    color: K.chartColors[0],
                    name: "Consommation",
                    type: "scatter",
                    tooltip: {
                        pointFormat: "<b>{point.y:,.2f}</b>  " + this.consUnit + " <b>{point.x:,.1f}</b> °C",
                    },
                    data: this.tempSignature.points || []//
                },
                {
                    xAxis: 0,
                    color: 'red',
                    lineWidth: 1.5,
                    name: "Regressions",
                    type: "line",
                    tooltip: {
                        headerFormat: "",
                        pointFormat: "<b>{point.y:,.2f}</b>  " + this.consUnit + " <b>{point.x:,.1f}</b> °C",
                    },
                    data: this.serieLines || []
                },
            ]
        };
        this.drawCassureVertical();
    }

    drawCassureVertical() {
        if (!this.chart) {
            return;
        }
        if (!this.tempSignature.cassure) {
            console.error("Cassure not defined for", this.site.year);
            return;
        }

        const line = {
            id: 'cassure',
            label: 'Cassure:' + this.tempSignature.finalCassure.toFixed(2),
            dashStyle: 'longdashdot',
            color: '#FF0000',
            width: 1,
            value: this.tempSignature.finalCassure
        } as any;
        this.tempSignature.points.forEach(item => {
            if (item.y < this.yMin) this.yMin = item.y;
        });

        if (this.chart.xAxis && this.chart.xAxis.length > 0) {
            this.chart.xAxis[0].removePlotLine('cassure');
            this.chart.xAxis[0].addPlotLine(line as AxisPlotLinesOptions);
        }
    }

    saveSignatureServer(save = false) {
        this.tempSignature.calc();
        this.drawSignatureByIndex(this.site.selectedConfigForPdmAndYear.cassure_chosen_reg_index);
        if (save) {
            console.log('Saving cassure', this.site.selectedConfigForPdmAndYear);
            this.site.updateSignatureConfig(this.tempSignature);
        }
    }

    manualValueClear() {
        this.tempSignature.manual = undefined;
        this.site.updateSignatureConfig(this.tempSignature);
    }

    onChartInstance(chart) {
        console.log("onChartInstance")
        this.chart = chart;
        this.bootstrap();
    }

    ngOnChanges(changes: SimpleChanges) {
        // console.log("ngOnChanges---------- ", changes);
        this.bootstrap('ngOnChanges');
    }

    raiseError(message, year: number, severity, type = 'data') {
        this.errors.push({message, severity, ts: H.unixTs(), year, type});
    }
}
