<div class="flex-container full-height" fxLayout="row" cdkDropListGroup>

    <div fxFlex="20" class="pr-16" *ngIf="site&&site.siteLoadingStatusInt>2">
        <mat-card class="p-0 m-0 mt-16 mr-8">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxFlex *ngFor="let status of myapp.k.StatusDisplayName|keyvalue" class="status-selector"
                     (click)="filterBy('status',status.key)"
                     [ngClass]="{active:filterGetIsActive('status',status.key)}">
                    <mat-icon class="text-green"> {{ myapp.k.StatusIcon[status.key] }}</mat-icon>
                    <h4 class="m-0 ">{{ status.value }}</h4>
                </div>
            </div>
        </mat-card>

        <mat-card class="p-0 m-0 mt-16 mr-8">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxFlex *ngFor="let priority of myapp.k.PriorityDisplayName|keyvalue" class="status-selector"
                     (click)="filterBy('priority',priority.key)"
                     [ngClass]="{active:filterGetIsActive('priority',priority.key)}">
                    <mat-icon mat-sm-chip [style]="{color:myapp.k.PriorityColor[priority.key]}">
                        flag_circle
                    </mat-icon>
                    <h4 class="m-0 ">{{ priority.value }}</h4>
                </div>
            </div>
        </mat-card>

        <mat-divider></mat-divider>
        <mat-card fxLayout="column" class="p-0 m-0 mt-16 mr-8">

            <mat-button-toggle-group fxLayout="row" fxLayoutAlign="space-between center" multiple="true">
                <mat-button-toggle fxFlex value="onlyMyActions" (click)="filterBy('onlyMyActions',1)">
                    <mat-icon>person</mat-icon>
                    Mes actions
                </mat-button-toggle>
                <mat-button-toggle fxFlex value="onlyGc" (click)="filterBy('gc',1)">
                    <mat-icon>domain</mat-icon>
                    GC
                </mat-button-toggle>
                <mat-button-toggle fxFlex value="onlyGc" (click)="filterBy('onlyPending',1)">
                    <mat-icon>domain</mat-icon>
                    À traiter
                </mat-button-toggle>
            </mat-button-toggle-group>

            <div class="p-4 full-width">
                <mat-form-field appearance="legacy" class="full-width">
                    <mat-label>Recherche par mot clé</mat-label>
                    <input matInput placeholder="Mot clé" [ngModel]="filtersMap.get('key')" (keyup)="filterDatatable($event)">
                    <mat-icon (click)="filterDatatable(null)" matSuffix>close</mat-icon>
                </mat-form-field>
            </div>

        </mat-card>

        <app-chapters-cpn id="done"
                          (OnSelectChapter)="selectChapter($event)"
                          [chapters]="site.chapters"
                          [uidSite]="site.clientSite.uid"
                          [type]="'actions'">
        </app-chapters-cpn>
        <div class="p-4">
            <button *ngIf="checkIsChapterDeletable" (click)="deleteSelectedChapter(selectedChapter)"
                    mat-raised-button color="accent"
                    class="btn-xs-25 m-8">
                Supprimer le chapitre {{ selectedChapter.title }}
            </button>
        </div>

        <mat-divider></mat-divider>
        <div class="p-8" *ngIf="site.siteLoadingStatusInt>1000">
            <button (click)="simulateDefaultTariff(2017)">2017</button>
            <button (click)="simulateDefaultTariff(2018)">2018</button>
            <button (click)="simulateDefaultTariff(2019)">2019</button>
            <button (click)="simulateDefaultTariff(2020)">2020</button>
            <button (click)="simulateDefaultTariff(2021)">2021</button>
            <button (click)="simulateDefaultTariff(2022)">2022</button>
            <button (click)="simulateDefaultTariff(2023)">2023</button>
        </div>

        <div fxLayout="column">
            <div fxLayoutAlign="space-between center" class="p-16 pt-8 pb-8 cursor-pointer"
                 [ngClass]="{'color-blue':filtersMap.has('draft')}"
                 (click)="filterBy('draft',1)" fxLayout="row">
                <mat-icon fxFlex="30px">mail</mat-icon>
                <span fxFlex>Brouillon</span>
                <span>({{ countDraft }})</span>
            </div>
            <div fxLayoutAlign="space-between center" class="p-16 pt-8 pb-8 cursor-pointer"
                 *ngIf="myapp.isAdminOrSuperUser"
                 [ngClass]="{'color-blue':filtersMap.has('deleted')}"
                 (click)="filterBy('deleted',1)" fxLayout="row">
                <mat-icon fxFlex="30px">delete</mat-icon>
                <span fxFlex>Corbeille</span>
                <span>({{ countDelete }})</span>
            </div>
            <div fxLayoutAlign="space-between center" class="p-16 pt-8 pb-8 cursor-pointer"
                 [ngClass]="{'color-blue':filtersMap.has('commented')}"
                 (click)="filterBy('commented',1)" fxLayout="row">
                <mat-icon fxFlex="30px">comment</mat-icon>
                <span fxFlex>Actions commentées</span>
                <span>({{ countCommented }})</span>
            </div>
            <div fxLayoutAlign="space-between center" class="p-16 pt-8 pb-8 cursor-pointer"
                 [ngClass]="{'color-blue':filtersMap.has('noChapter')}"
                 (click)="filterBy('noChapter',1)" fxLayout="row">
                <mat-icon fxFlex="30px">comment</mat-icon>
                <span fxFlex>Sans chapitre</span>
                <span>({{ countNoChapter }})</span>
            </div>
        </div>

    </div>

    <div fxFlex="80" fxLayout="column" class="pr-16">
        <div class="pb-0 pt-16" fxLayout="row">
            <!-- table filters-->
            <mat-card fxFlex="50" class="m-0 years-filter-bloc" [@animate]="{ value: '*', params: { y: '90px', delay: '300ms' } }">
                <div fxLayout="column">
                    <div class="years-bar" fxLayout="row" fxLayoutAlign="space-between center">
                        <div fxFlex="">Actions proposées en</div>
                        <mat-button-toggle-group fxLayout="row" class="mt-4" [multiple]="true">
                            <mat-button-toggle [checked]="filterGetIsActive('ts_proposed',y)"
                                               *ngFor="let y of yearsAll" class="btn-toggle-sm"
                                               [disabled]="!yearsProposed.includes(y)"
                                               (click)="filterBy('ts_proposed',y)"> {{ y }}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <div class="years-bar" fxLayout="row" fxLayoutAlign="space-between center">
                        <div fxFlex="">Actions par délai en</div>
                        <mat-button-toggle-group fxLayout="row" class="mt-4" [multiple]="true">
                            <mat-button-toggle [checked]="filterGetIsActive('ts_due',y)"
                                               *ngFor="let y of yearsAll" class="btn-toggle-sm"
                                               [disabled]="!yearsDue.includes(y)"
                                               (click)="filterBy('ts_due',y)"> {{ y }}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <div class="years-bar" fxLayout="row" fxLayoutAlign="space-between center">
                        <div fxFlex="">Actions terminées en</div>
                        <mat-button-toggle-group fxLayout="row" class="mt-4" [multiple]="true">
                            <mat-button-toggle [checked]="filterGetIsActive('ts_completed',y)"
                                               *ngFor="let y of yearsAll" class="btn-toggle-sm"
                                               [disabled]="!yearsCompleted.includes(y)"
                                               (click)="filterBy('ts_completed',y)"> {{ y }}
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
            </mat-card>

            <!-- econ summary  -->
            <mat-card fxFlex class="p-8 mt-0 mb-0 mr-0 actions-summary-bloc"
                      [@animate]="{ value: '*', params: { y: '50px', delay: '600ms' } }">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxFlex="20" class="head color-blue">Récapituatif</div>
                    <div fxFlex class="caption">[des  {{ proccessedCount }}
                        <i *ngIf="!selectedActions.size">APE affichées: </i>
                        <i *ngIf="selectedActions.size">Actions selectionnées: </i>]
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between start">
                    <div fxLayout="column" fxFlex="60">
                        <div fxLayout="row">
                            <div fxFlex="33">
                                <div class="label">Total des économies</div>
                                <span *ngIf="myapp.isNumber(proccessedTotalEcon)">
                                    {{ proccessedTotalEcon|number:"1.0-0" }} Fr.
                                </span>
                            </div>
                            <div fxFlex="33">
                                <div class="label">Invest. net</div>
                                <span *ngIf="myapp.isNumber(proccessedTotalInvestReal)">
                                    {{ proccessedTotalInvestReal|number:"1.0-0" }} Fr.
                                </span>
                            </div>
                            <div fxFlex="33">
                                <div class="label">Invest. brut</div>
                                <span *ngIf="proccessedTotalInvest&&myapp.isNumber(proccessedTotalInvest)">
                                    {{ proccessedTotalInvest|number:"1.0-0" }} Fr.
                                </span>
                            </div>
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="33">

                            </div>
                            <div fxFlex="33">
                                <div class="label">Payback net</div>
                                {{ proccessedPaybackNet|number:"1.2-2" }} ans
                            </div>
                            <div fxFlex="33">
                                <div class="label">Payback brut</div>
                                {{ proccessedPaybackBrut|number:"1.2-2" }} ans
                            </div>
                        </div>
                    </div>
                    <div fxFlex class="details-bloc">
                        <div class="label">Économie par agent</div>
                        <div *ngFor="let it of proccessedEconByTariff|keyvalue">
                            <div fxLayout="row" fxLayoutAlign="space-between end" class="value">
                                <div fxFlex class="agent-label" (click)="myapp.showConsole(it,myapp.k.EnergyAgentsDisplayNames)">
                                    {{ it.key }}
                                </div>
                                <span>{{ it.value|number }}</span>
                                <span class="unit ml-4 text-muted"> {{ proccessedEconByTariffUnit[it.key] }}  </span>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>

        </div>

        <!-- Selected actions panel -->
        <mat-card *ngIf="selectedActions.size" class="p-16 pr-4 m-0 mt-8"
                  [@animate]="{ value: '*', params: { y: '50px', delay: '300ms' } }">
            <div class="p-4" fxLayout="row" fxLayoutAlign="space-between center">
                <span>{{ selectedActions.size }} actions selectionnées</span>
                <span fxFlex> </span>
                <div fxLayout="row" fxFlex="300px" class="p-0" fxLayoutAlign="space-between center">
                    <mat-select placeholder="Priorité" fxFlex="100px">
                        <mat-option [value]="priority.key" *ngFor="let priority of myapp.k.PriorityDisplayName|keyvalue">
                            {{ priority.value }}
                        </mat-option>
                    </mat-select>
                    <span fxFlex></span>
                    <mat-select placeholder="Status" fxFlex="100px">
                        <mat-option [value]="status.key" *ngFor="let status of myapp.k.StatusDisplayName|keyvalue">
                            {{ status.value }}
                        </mat-option>
                    </mat-select>
                    <button class="btn-xs-25" mat-raised-button>Sauvegarder</button>
                </div>
            </div>
        </mat-card>

        <!-- filter summary and table extracols  -->
        <div *ngIf="!selectedActions.size" class="p-4" fxLayout="row" fxLayoutAlign="space-between center"
             [@animate]="{ value: '*', params: { y: '50px', delay: '300ms' } }">
            <div *ngIf="filtersMap&&filtersMap.size">
                Filtre:
            </div>
            <div *ngIf="!filtersMap||!filtersMap.size" (dblclick)="refreshHeader()">Pas de filtre</div>
            <div *ngFor="let f of filtersMap|keyvalue">
                <ng-container *ngIf="filtersMultipleValue.includes(f.key)">
                    <b class="m-8 color-blue">{{ extracolsLabel[f.key] }}:</b>
                    <mat-chip *ngIf="f.key!=='chapterId'" class="actions-filter-chips">{{ f.value }}</mat-chip>
                </ng-container>
                <ng-container *ngIf="!filtersMultipleValue.includes(f.key)">
                    <mat-chip class="actions-filter-chips">{{ extracolsLabel[f.key] }}:</mat-chip>
                </ng-container>
                <ng-container *ngIf="f.key==='chapterId'">
                    <mat-chip class="actions-filter-chips">
                        {{ chaptersMap.get(f.value) }}
                    </mat-chip>
                </ng-container>
            </div>
            <div fxFlex>
                <mat-icon class="inline cursor-pointer"
                          *ngIf="filtersMap&&filtersMap.size"
                          (click)="resetFilter()"
                          inline>delete
                </mat-icon>
            </div>
            <div fxFlex="200px" class="align-right" fxLayoutAlign="end center" fxLayout="row">
                {{ filteredActions.length }} actions
                <button mat-icon-button (click)="printPreview()">
                    <mat-icon>print</mat-icon>
                </button>
                <button mat-icon-button (click)="exportExcel()">
                    <mat-icon>table_chart</mat-icon>
                </button>
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <div mat-menu-item *ngFor="let extraCol of extracolsMap|keyvalue" (click)="extraColToggle($event,extraCol.key)">
                        <mat-checkbox [checked]="extraCol.value===1"></mat-checkbox>
                        <span>{{ extracolsLabel[extraCol.key] }}</span></div>
                </mat-menu>
            </div>
        </div>

        <!--Table and header -->
        <ng-container *ngIf="viewMode==='std'">
            <table class="actions-table-header">
                <tr class="action-row head">
                    <td class="cell-checkbox" style="padding-left: 20px">-</td>
                    <td #headcell [attr.field]="'num'" class="cell-action-num text-center"> Num</td>
                    <td #headcell [attr.field]="'title'" class="cell-title"> Titre</td>
                    <td #headcell [attr.field]="'priority'" class="cell-priority"> Priorité</td>
                    <td #headcell [attr.field]="'status'" class="cell-priority"> Statut</td>
                    <td #headcell [attr.field]="'economies_total'" class="cell-economy text-right"> Economies</td>
                    <td #headcell *ngFor="let fl of proccessedAvailableFluids"
                        (click)="sort('fluids_'+fl,$event.target)"
                        [attr.field]="'fluids_'+fl" class="cell-fluid text-right cursor-pointer"> {{ myapp.k.FluidsLabel[fl] }}
                    </td>
                    <td></td>
                    <td #headcell [attr.field]="'ts_due'" class="cell-date">Délai</td>
                    <ng-container *ngFor="let extraCol of extracolsMap|keyvalue">
                        <td #headcell [attr.field]="extraCol.key" *ngIf="extraCol.value===1" class="cell-date">{{ extracolsLabel[extraCol.key] }}</td>
                    </ng-container>
                </tr>
            </table>
            <div fxFlex style="overflow-y: scroll">
                <table class="actions-table" cdkDropList cdkDropListSortingDisabled>
                    <tr action-row
                        [ngClass]="{active:selectedActions.has(act.uid)}"
                        cdkDrag [cdkDragData]="act" class="action-row"
                        *ngFor="let act of filteredActions"
                        [action]="act"
                        [availableFluids]="proccessedAvailableFluids"
                        [forceUpdate]="act.ts_updated"
                        [extracolsMap]="extracolsMap"
                        (cb)="cbFromRow(act,$event)"></tr>
                </table>
            </div>
        </ng-container>


        <mat-card *ngIf="viewMode==='print'" class="m-0 p-4">
            <actions-print-preview
                    [availableFluids]="proccessedAvailableFluids"
                    [chaptersMap]="chaptersMap"
                    [extracolsLabel]="extracolsLabel"
                    [filtersMultipleValue]="filtersMultipleValue"
                    [filtersMap]="filtersMap"
                    [actions]="filteredActions"></actions-print-preview>
        </mat-card>
    </div>

</div>

