import {Component, OnInit, ViewChild} from '@angular/core';
import {MatProgressBar} from "@angular/material/progress-bar";
import {MatButton} from "@angular/material/button";
import {ActivatedRoute, Router} from "@angular/router";
import {AppService} from "../../../shared/services/app.service";
import {AuthService} from "../../../shared/services/auth.service";
import {User} from "../../../shared/models/models";
import {getAuth, createUserWithEmailAndPassword} from "firebase/auth";

@Component({
    selector: 'app-invite',
    template: `
        <div class="page-wrap h-full">
            <div class="session-form-hold">
                <mat-progress-bar mode="determinate" class="session-progress"></mat-progress-bar>
                <mat-card>
                    <mat-card-content>
                        <div class="text-center pt-8 pb-16">
                            <img width="60px" src="assets/images/enerplan.svg" alt="" class="mb-8">
                            <p class="text-muted m-0">Veuillez saisir votre nouveau mot de passe</p>
                            <div class="error-bloc" *ngIf="inviteError!==''">Erreur: {{ inviteError }}</div>
                        </div>
                        <form #fpForm="ngForm" (ngSubmit)="updatePassword()">

                            <div class="">
                                <mat-form-field class="full-width">
                                    <input matInput name="newPassword" required [(ngModel)]="newPassword"
                                           type="password" #pass1 placeholder="Mot de passe" value="">
                                </mat-form-field>

                                <mat-form-field class="full-width">
                                    <input matInput name="email" required [(ngModel)]="newPasswordConf"
                                           type="password" #pass2 placeholder="Confirmer le mot de passe" value="">
                                </mat-form-field>

                                <small *ngIf="fpForm.errors && (fpForm.dirty || fpForm.touched) && (fpForm?.errors?.required)"
                                       class="form-error-msg"> Email is required </small>
                                <small *ngIf="fpForm.errors && (fpForm.dirty || fpForm.touched) && (fpForm?.errors?.required)"
                                       class="form-error-msg"> Email is required </small>
                                <small *ngIf="fpForm.errors && (fpForm.dirty || fpForm.touched) && (fpForm?.errors?.required)&&pass1.value!==pass2.value"
                                       class="form-error-msg"> Les mots de passe ne concordent pas </small>
                            </div>

                            <button mat-raised-button class="mat-primary full-width mb-16"
                                    [disabled]="fpForm.invalid||pass1.value!==pass2.value">
                                Envoyer
                            </button>
                            <div class="text-center">
                                <a [routerLink]="'/session/login'" class="mat-primary text-center full-width">
                                    Connection
                                </a>
                                <span fxFlex></span>
                                <a [routerLink]="'/'" class="mat-primary text-center full-width">
                                    Accueil
                                </a>
                            </div>
                        </form>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>


    `,
    styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {
    userEmail;
    token: string;
    newPassword: string;
    newPasswordConf: string;
    invitedUser: User;
    inviteError: string = "";
    @ViewChild(MatProgressBar) progressBar: MatProgressBar;
    @ViewChild(MatButton) submitButton: MatButton;

    constructor(private activatedRoute: ActivatedRoute,
                public route: Router, public myapp: AppService,
                public auth: AuthService) {
    }

    ngOnInit() {
        this.activatedRoute.params
            .subscribe(params => {
                    this.token = params['token'];
                    this.verifyToken();
                }
            );
    }

    verifyToken() {
        console.log("VerifyToken()");
        this.myapp.api.getInvitation(this.token).subscribe(resp => {
            if (resp.status === 1) {
                this.invitedUser = new User(resp.body);
                console.log('verifyToken()', this.invitedUser.email);
            } else {
                this.inviteError = "Invitaion expirée !";
                this.myapp.showError("Invitation expirée !");
            }
        })
    }


    updatePassword() {
        console.log('updatePassword()', this.newPassword);
        const auth = getAuth();
        createUserWithEmailAndPassword(getAuth(), this.invitedUser.email, this.newPassword)
            .then((resp) => {
                console.log('updatePassword()', resp.user);
                this.myapp.api.confirmInvitation(this.invitedUser.uid, resp.user.uid)
                    .subscribe(resp => {
                        if (resp.status === 1) {
                            this.invitedUser = new User(resp.body);
                            console.log('verifyToken()', this.invitedUser.email);
                        } else {
                            this.inviteError = "Invitaion expirée !";
                            this.myapp.showError("Invitation expirée !");
                        }
                    })
                this.myapp.showMessage("Compte activé avec succès !");
                this.route.navigate(['/session/login']);
            })
            .catch(error => {
                if (error.code === 'auth/weak-password')
                    this.myapp.showError("Mot de passe trop faible");
                if (error.code === 'auth/invalid-action-code')
                    this.myapp.showError("Code reset du mot de passe invalide");
                console.error(error);
            })
    }

}
