import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ClientSite, EpClient, SiteAccess, User} from "../../../../shared/models/models";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {AppService} from "../../../../shared/services/app.service";
import {SiteService} from "../../../../shared/services/site.service";
import {AppConfirmService} from "../../../../shared/services/app-confirm/app-confirm.service";
import {H} from "../../../../shared/helpers/H";
import {deleteField} from "firebase/firestore";

@Component({
    selector: 'app-clients',
    template: `
        <div fxLayout="row" fxLayoutAlign="space-between none">
            <!-- left list -->
            <mat-card fxFlex="460px" class="default full-width">
                <mat-card-title class="p-8 list-user-head">Client EP
                    (<span *ngIf="clients">{{ clients.length }}</span>)
                </mat-card-title>

                <mat-card-content fxLayout="column" fxLayoutAlign="start stretch" class="flex-container p-0">

                    <div class="scroller">
                        <mat-list class="full-widt h mb-32 compact-list" fxLayout="column" fxLayoutAlign="start none">
                            <ng-container *ngFor="let cl of clients">
                                <mat-list-item class="full-wid th p-8"
                                               fxLayout="row" fxLayoutAlign="space-between center"
                                               [ngClass]="{active:selectedClient&&selectedClient.uid===cl.uid}"
                                               (click)="selectClient(cl)">

                                    <h6 class="m-0 ml-8" fxFlex>
                                        <span class="text-muted"> {{ cl.name }} </span>
                                        <span class="color-blue font-weight-bold">  ({{ cl.sites_count }})</span>
                                    </h6>
                                </mat-list-item>
                            </ng-container>
                        </mat-list>
                    </div>
                </mat-card-content>
            </mat-card>

            <!-- main body -->
            <mat-card class="p-0" fxFlex>
                <mat-card-title>
                    <h1 class="p-16 pb-0" *ngIf="selectedClient"> Edition de {{ selectedClient.name }}</h1>
                </mat-card-title>
                <mat-card-content class="p-0 flex-container">
                    <div class="p-8 flex-container" fxLayout="row">
                        <!-- main form -->
                        <div fxFlex="40">
                            <form [formGroup]="formPdm"
                                  *ngIf="formPdm&&selectedClient" class="p-8">
                                <h2 class="p-8 pb-0">Informations de base</h2>
                                <div fxLayout="row">
                                    <div class="p-4" style="width: 95%">
                                        <div class="bloc-user-email">
                                            UID: <b class="color-blue">{{ selectedClient.uid }}</b></div>
                                        <mat-divider class="pb-16"></mat-divider>

                                        <mat-form-field class="full-width">
                                            <input matInput formControlName="name" placeholder="Nom du client">
                                        </mat-form-field>
                                        <mat-form-field class="full-width">
                                            <input matInput formControlName="address" placeholder="Adresse">
                                        </mat-form-field>

                                        <div fxLayout="row" class="pt-16">
                                            <button mat-raised-button color="accent" [disabled]="!formPdm.valid" (click)="createClient()">Créer
                                            </button>
                                            <span fxFlex></span>
                                            <button mat-raised-button color="primary" [disabled]="!formPdm.valid" (click)="saveClient()">Sauvegarder
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>

                        <!-- roles form   -->
                        <div fxFlex="60">
                            <ng-container *ngIf="selectedClient">
                                <h2 class="p-8 pb-0">Bâtiments du client</h2>
                                <div class="p-8">
                                    <div fxLayout="row" fxLayoutAlign="space-between center">
                                        <mat-form-field fxFlex>
                                            <input matInput [(ngModel)]="newBatRef"
                                                   [specialCharacters]="[ '-' ,'_']"
                                                   mask="AAAAAAAAAAAAAAAAAAAAAAAAAA" placeholder="Réf">
                                        </mat-form-field>
                                        <mat-form-field fxFlex>
                                            <input matInput [(ngModel)]="newBatName" placeholder="Nom du bâtiment">
                                        </mat-form-field>
                                        <button mat-raised-button (click)="createBat()" class="btn-xs-25">Créer bâtiment</button>
                                    </div>
                                </div>
                                <div class="scroller">
                                    <mat-card class="p-4 m-8">
                                        <table class="table-stats">
                                            <tr class="header-row">
                                                <td class="col1"></td>
                                                <td class="col1">Bâtiment</td>
                                                <td>Ape</td>
                                                <td>Comm</td>
                                                <td>Partages</td>
                                                <td>Premier proto</td>
                                                <td>Dernier proto</td>
                                                <td>Rel. bmens</td>
                                                <td>Rel. MDG</td>
                                            </tr>
                                            <tr *ngFor="let bat of selectedClientSites;let i=index">
                                                <td class="index-cell" (click)="printStats(bat)">
                                                    {{ i + 1 }}
                                                </td>
                                                <td class="col1 color-blue font-weight-bold" (click)="printStats(bat)">
                                                    {{ bat.name }}
                                                </td>
                                                <ng-container *ngIf="siteStatsMap.get(bat.uid) as stats">
                                                    <td>{{ stats['apeCount'] }}</td>
                                                    <td>{{ stats['commentCount'] }}</td>
                                                    <td>{{ stats['eventCount'] }}</td>
                                                    <td>
                                                        <ng-container *ngIf="stats['publishFirst'] as first">
                                                            <small>{{ first['date_str'] }}</small>
                                                            <b class="color-blue">({{ first['page_count'] }})</b>
                                                        </ng-container>
                                                    </td>
                                                    <td>
                                                        <ng-container *ngIf="stats['publishLast'] as last">
                                                            <small>{{ last['date_str'] }}</small>
                                                            <b class="color-blue">({{ last['page_count'] }})</b>
                                                        </ng-container>
                                                    </td>
                                                    <td>{{ stats['releveCount'] }}</td>
                                                    <td>{{ stats['releveCountMdg'] }}</td>
                                                </ng-container>
                                        </table>
                                    </mat-card>
                                </div>
                            </ng-container>

                        </div>
                    </div>
                </mat-card-content>
            </mat-card>


        </div>
    `,
    styleUrls: ['./clients.component.scss']
})

export class ClientsComponent implements OnInit {
    formPdm: FormGroup = null;
    newBatRef: string;
    newBatName: string;
    selectedSiteAccess: SiteAccess;
    clients: EpClient[];
    siteStatsMap: Map<string, any> = new Map<string, ClientSite>();
    selectedClient: EpClient;
    selectedClientSites: ClientSite[];
    public customPatterns = {'0': {pattern: new RegExp('\[a-zA-Z\]')}};

    constructor(
        private fb: FormBuilder,
        public myapp: AppService,
        public site: SiteService,
    ) {

    }

    ngOnInit(): void {
        this.myapp.storeCurrentRoute();

        this.formPdm = this.fb.group({
            name: ['', [Validators.required]],
            address: ["", [Validators.required]],
        });
        this.loadClients();
    }

    loadClients() {
        this.myapp.api.getClients().subscribe(resp => {
            this.clients = resp.body.map(it => {
                const cl = new EpClient(it);
                if (this.selectedClient && this.selectedClient.uid === cl.uid)
                    this.selectClient(cl);
                return cl;
            });
        })
    }

    createClient() {
        const uid = H.randomStr(30).toLowerCase();
        const data = {...this.formPdm.value, uid, sites_count: 0, lang: 'fr'};
        console.log('createClient', data);
        this.myapp.api.saveClients(data).subscribe(resp => {
            console.log('createClient:resp', resp);
            this.loadClients();
        })
    }

    selectClient(cl: EpClient) {
        this.selectedClient = cl;
        if (!this.formPdm || !this.selectedClient) return;
        this.formPdm.controls['name'].patchValue(this.selectedClient.name);
        this.formPdm.controls['address'].patchValue(this.selectedClient.address);

        this.myapp.api.getSitesOfClient(cl.uid).subscribe(resp => {
            this.selectedClientSites = resp.body.map(it => new ClientSite(it));
            this.selectedClientSites.forEach(site => {
                // getSitesStats
                if (!this.siteStatsMap.has(site.uid))
                    this.myapp.api.getSitesStats(site.uid).subscribe(respStats => {
                        this.siteStatsMap.set(site.uid, respStats.body);
                    });
            });
        });
    }

    printStats(batObj: ClientSite) {
        console.log("Print stats", batObj);
        console.log("Stats", this.siteStatsMap.get(batObj.uid));
    }

    saveClient() {
        const data = {...this.formPdm.value, uid: this.selectedClient.uid};
        this.myapp.api.saveClients(data).subscribe(resp => {
            console.log('createClient:resp', resp);
            this.loadClients();
            this.myapp.showMessage("Client sauvegardé avec succès !")
        });
    }

    createBat() {
        console.log('createBat()', this.newBatName, this.newBatRef);
        if (this.newBatName && this.newBatName !== '') {
            if (this.newBatRef.length < 4 || this.newBatName.length < 5) {
                this.myapp.showError("Nom ou réf trop court !");
                return;
            } else {
                const newSite = new ClientSite();
                newSite.uid = H.randomStr(30).toLowerCase();
                newSite.uid_client = this.selectedClient.uid;
                newSite.client_name = this.selectedClient.name;
                newSite.releve_day = 'WED';
                newSite.ref = this.newBatRef;
                newSite.name = this.newBatName;
                newSite.address = this.selectedClient.address;
                newSite.ts_created = H.unixTs();
                this.myapp.api.createSite(newSite).subscribe(resp => {
                    console.log('createBat():Resp', resp);
                    this.selectClient(this.selectedClient);
                    this.myapp.showMessage("Bâtiment créé avec succès !")
                });
            }
        }
    }
}
