<div fxLayout="column" class="full-width">
    <div fxLayout="row" id="topbar" class="toolbar-top" fxLayoutAlign="space-between center">
        <h3 fxFlex="280px" (dblclick)="calcGoals()">Dossier grands consommateurs</h3>
        <div fxFlex="60px" (dblclick)="clearAllRevisions()">{{ selectedYear }}</div>
        <div fxFlex class="btn-hidden" *ngIf="myapp.isAdmin" (dblclick)="clearAllReports()">Tout supprimer</div>
        <div class="pr-32">
            <a *ngIf="pdfLink" [@animate]="{value:'*',params:{delay:'800ms',y:'50px'}}"
               mat-raised-button color="warn" class="mr-4 btn-xs-25" [href]="pdfLink" target="_blank">
                <mat-icon style="font-size: 18px" inline>print</mat-icon>
                <span> {{ pdfAge * 1000|amTimeAgo }}</span>
            </a>
        </div>
    </div>
    <div fxLayout="row" id="body">
        <!--LEFT PANEL -->
        <div fxLayout="column" class="left-panel" fxFlex="300px">
            <ng-container *ngFor="let report of site.reportsItemsRef">
                <div [ngClass]="{active:site.selectedReportRef&&site.selectedReportRef.uid===report.uid}"
                     class="button-select-year-ref cursor-pointer"
                     [routerLink]="['/suivi/']"
                     (click)="selectReportRef(report)">
                    <span>Convention </span> - {{ report.year }}
                </div>
                <div *ngIf="site.selectedReportRef&&site.selectedReportRef.uid===report.uid">
                    <ng-container *ngFor="let rep of site.reportsIteratifs">
                        <div class="button-select-year cursor-pointer"
                             [ngClass]="{active:site.selectedReport&&site.selectedReport.uid===rep.uid}"
                             *ngIf="!rep.isHidden"
                             [routerLink]="['/suivi/'+site.clientSite.uid+'/'+rep.year]">
                            <span>Rapport de suivi </span> {{ rep.year }}
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <hr>
            <button mat-raised-button color="accent" class="m-8 mt-32"
                    (click)="createSuivi()">Créer un nouveau rapport
            </button>

            <ng-container *ngIf="site.selectedReport&&site.selectedReport.validation">

                <mat-card *ngFor="let rev of this.site.selectedReport.validation" class="p-4 m-16 mt-4 mb-4 revision-item"
                          [class.validated]="rev.status==='VALIDATED'">
                    <div>Statut <b>{{ rev.status }}</b> Version: <b>{{ rev.version }}</b></div>
                    <div>Date: {{ rev.dateFull }}</div>
                    <div>Par {{ rev.user.gender }} {{ rev.user.lastName }}</div>
                    <div *ngIf="rev.message" class="message"><span>Message:</span> {{ rev.message }}</div>

                    <div fxLayout="row" *ngIf="rev.isSubmission">
                        <div fxLayout="row" class="but-with-link" fxLayoutAlign="space-between center"
                             (click)="openRevisionLink(rev,false)">
                            <mat-icon inline fxFlex="30px">picture_as_pdf</mat-icon>
                            <span>PDF sans annexes</span>
                        </div>
                        <div fxLayout="row" class="but-with-link" fxLayoutAlign="space-between center"
                             (click)="openRevisionLink(rev,true)">
                            <mat-icon inline fxFlex="30px">picture_as_pdf</mat-icon>
                            <span>PDF avec annexes</span>
                        </div>
                    </div>

                </mat-card>
            </ng-container>

        </div>

        <!--BODY -->
        <div fxFlex>
            <div fxLayout="column" class="main-page" *ngIf="site.selectedReport">
                <div class="jumbotron relative">
                    <h1 [title]="site.selectedReport.uid" (dblclick)="getBlocsHtml()">
                        <span *ngIf="isRef">Situation de référence - {{ site.selectedReportRef.year }}</span>
                        <span *ngIf="!isRef">Suivi itératif - {{ site.selectedReport.year }}</span>
                    </h1>
                    <p (dblclick)="syncSuiviDocuments()">
                        Le présent rapport a pour but d'analyser la consommation énergétique de l’entreprise et
                        d'établir la liste des facteurs d'influences afin
                        d'identifier les opportunités d'amélioration par le biais d'actions de performance énergétique
                        (APE).
                    </p>

                    <ng-container *ngIf="!site.selectedReport.isValidated">
                        <button mat-raised-button *ngIf="myapp.isAdminOrSuperUser&&isOldPdf" class="btn-xs-25"
                                color="primary"
                                (click)="blocValidation.toggleAttribute('hidden')">
                            <ng-container *ngIf="!site.selectedReport.isSubmitted">
                                <span *ngIf="site.clientSite.type_suivi!=='RAO'">Soumettre au canton</span>
                                <span *ngIf="site.clientSite.type_suivi==='RAO'">Soumetre au chef de projet</span>
                            </ng-container>
                            <span *ngIf="site.selectedReport.isSubmitted">Re-Soumettre au canton {{ site.clientSite.type_suivi }}</span>
                        </button>
                        <button mat-raised-button *ngIf="site.selectedReport.isSubmitted" class="btn-xs-25"
                                (click)="blocMessageVisible=true;revisionNextStatus='COMMENT'">
                            Ajouter un commentaire
                        </button>
                        <button mat-raised-button *ngIf="site.selectedReport.isSubmitted&&myapp.isCanton" class="btn-xs-25"
                                color="accent"
                                (click)="createRevision('VALIDATED')">
                            Valider le rapport
                        </button>

                        <div #blocValidation [hidden]="isOldPdf||myapp.isCanton||justSubmitted">
                            <mat-button-toggle-group fxLayout="row" class="mt-4">
                                <mat-button-toggle (click)="getBlocsHtml()">1- Générer le rapport PDF
                                </mat-button-toggle>
                                <ng-container *ngIf="!isOldPdf">
                                    <mat-button-toggle (click)="syncSuiviDocuments()">
                                        2- Analyse des annexes
                                    </mat-button-toggle>
                                    <mat-button-toggle (click)="mergeFiles()" *ngIf="files_synced">
                                        3- Joindre les annexes au pdf
                                    </mat-button-toggle>
                                    <mat-button-toggle (click)="blocMessageVisible=true"
                                                       *ngIf="reportMergeOutputPdfInfos['Pages']&&site.clientSite.type_suivi!=='RAO'">
                                        4- Soumettre au canton la version V{{ this.site.selectedReport.nextRevisionVersion }}
                                    </mat-button-toggle>
                                </ng-container>
                            </mat-button-toggle-group>

                            <div class="generation-log-item" fxLayout="row">
                                <div class="row-head" fxFlex="170px">Rapport sans annexes</div>
                                <div class="row-data">
                                    <span *ngIf="!pdfAge">Pdf non Généré</span>
                                    <span *ngIf="pdfAge">Généré <b> {{ pdfAge * 1000|amTimeAgo }}</b> le {{ pdfDate }}</span>
                                    <span *ngIf="!isOldPdf&&reportMainPdfInfo"> {{ reportMainPdfInfo['Pages'] }}
                                        pages</span>
                                    <div *ngIf="pdfAge&&isOldPdf" class="color-red style-italic">
                                        <mat-icon inline>warning</mat-icon>
                                        Le PDF du rapport est généré il y a plus de 5min. Regenerer le rapport pour
                                        pouvoir le soumettre
                                    </div>
                                </div>
                            </div>
                            <div class="generation-log-item" *ngIf="reportFiles&&reportFiles.length">
                                <div class="row-head" fxFlex="170px">Annexes</div>
                                <div class="row-data" fxFlex fxLayout="column">
                                    <div *ngFor="let f of reportFiles" fxLayout="row" class="row-inner">
                                        <div fxFlex>
                                            <strong class="color-blue">{{ f['original_title'] }}</strong><br>
                                            <b class="text-muted">Titre interne:</b>
                                            <span class="text-muted ml-4" *ngIf="f['Title']">{{ f['Title'] }}</span>
                                            <span class="text-muted ml-4" *ngIf="!f['Title']"><i>Vide</i></span>
                                            <b class="ml-8">Produit par:</b>
                                            <span class="text-muted ml-4">{{ f['Creator'] }}</span>
                                        </div>
                                        <span><b>{{ f['Pages'] }}</b> pages</span>
                                        <span fxFlex="180px"
                                              class="text-right smaller-text"> Papier: <b>{{ f['Page size'] }}</b></span>
                                    </div>
                                </div>
                            </div>

                            <div class="generation-log-item" fxLayout="row" *ngIf="reportMergeOutput">
                                <div class="row-head" fxFlex="170px">Génération finale</div>
                                <div class="row-data">
                                    {{ reportMergeOutput }}
                                    <a class="cursor-pointer smaller-text" [href]="reportMergeOutput" target="_blank">
                                        <mat-icon inline style="font-size: 17px;height: 23px;color: blue;">link
                                        </mat-icon>
                                    </a>
                                </div>
                            </div>

                            <div class="generation-log-item" fxLayout="row" *ngIf="reportMergeOutput">
                                <div class="row-head" fxFlex="170px">Nombre total de pages</div>
                                <div class="row-data">
                                    {{ reportMergeOutputPdfInfos['Pages'] }}
                                </div>
                            </div>
                            <div class="generation-log-item" fxLayout="row"
                                 *ngIf="reportMergeErrors&&reportMergeErrors.length">
                                <div class="row-head" fxFlex="170px">Erreurs</div>
                                <div class="row-data">
                                    <div *ngFor="let err of reportMergeErrors" fxLayout="row">
                                        {{ err }}
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div *ngIf="blocMessageVisible" class="bloc-message">
                            <div class="title">Ajouter un message (optionnel) et envoyer</div>
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <div fxFlex="80">
                                    <textarea [(ngModel)]="revisionMessage" placeholder="Votre message ici"></textarea>
                                </div>
                                <div fxFlex style="text-align: right">
                                    <button mat-raised-button color="primary" class="btn-xs-25"
                                            (click)="createRevision()">Envoyer
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <!--REVISIONS -->
                    <mat-card class="default revision-overlay" *ngIf="showRevision">
                        <mat-card-title fxLayout="row"><span>Révisions</span>
                            <span fxFlex></span>
                            <mat-icon (click)="showRevision=false">close</mat-icon>
                        </mat-card-title>
                        <mat-card-content>
                            <div class="timeline">
                                <div class="timeline-item" *ngFor="let rev of site.selectedReport.revisions">
                                    <div class="timeline-badge">
                                        <mat-icon class="icon-badge mat-bg-warn" *ngIf="rev.status==='VALIDATED'">
                                            check
                                        </mat-icon>
                                        <mat-icon class="icon-badge mat-bg-primary" *ngIf="rev.status==='EDITION'">
                                            edit
                                        </mat-icon>
                                        <mat-icon class="icon-badge mat-bg-accent" *ngIf="rev.status==='VALIDATING'">
                                            hourglass_full
                                        </mat-icon>
                                    </div>
                                    <div class="timeline-body">
                                        <div class="timeline-body-top" fxLayout="row">
                                            <a href="#" class="timeline-body-title mr-16">
                                                <b>{{ rev.user.firstName }} {{ rev.user.lastName }}</b></a>
                                            <span class="text-muted">{{ rev.date }}</span>
                                            <span fxFlex></span>
                                        </div>
                                        <div class="timeline-body-content">
                                            <p>{{ rev.message }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>

                    <div fxLayout="row" fxLayoutAlign="space-evenly end">
                        <div fxFlex="250px">
                            <mat-card *ngIf="site.selectedReport.revisions.length>0"
                                      class="revision-final-status" fxLayout="row" fxLayoutAlign="space-around center">
                                <span fxFlex>{{ site.selectedReport.revisions[0].status }}</span>
                                <button mat-icon-button (click)="showRevision=true">
                                    <mat-icon>history</mat-icon>
                                    {{ site.selectedReport.revisions.length }}
                                </button>
                            </mat-card>
                        </div>
                        <span fxFlex></span>
                        <div fxFlex="200px" class="last-updated">
                            Dernière mise à jour<br>
                            [{{ site.selectedReport.dateFull }}]
                        </div>
                    </div>
                </div>

                <div>
                    <suivi-page-de-garde #cover [report]="site.selectedReport" [reportRef]="site.selectedReportRef"
                                         hidden></suivi-page-de-garde>
                </div>

                <mat-tab-group>
                    <mat-tab label="Renseignements généraux">
                        <div #bloc class="bloc">
                            <h1>Renseignements généraux</h1>
                            <suivi-general-infos [isRef]="isRef"
                                                 [reportsCount]="site.reportsItems.length"
                                                 [reportRef]="site.selectedReportRef"
                                                 [report]="site.selectedReport"
                                                 formType="ref"
                                                 [year]="site.selectedReport.year">
                            </suivi-general-infos>
                        </div>
                    </mat-tab>
                    <mat-tab label="Données énergétiques">
                        <div #bloc class="bloc">
                            <h1>Données énergétiques</h1>
                            <p>Cette section contient les informations de consommation, d'achat et de production d'énergie.
                                Les valeurs sont non pondérées et non normalisées.
                                Ces valeurs sont utilisées pour le calcul de l'efficacité énergétique. </p>

                            <suivi-energy-evol *ngIf="!isRef"
                                               [report]="site.selectedReport"
                                               [reportRef]="site.selectedReportRef"
                                               [reportsOfSameConvention]="site.reportsIteratifs" [type]="'CONSOMMATION'">
                            </suivi-energy-evol>

                            <ng-container *ngIf="site.clientSite.tariff_config">
                                <suivi-energy [report]="site.selectedReport" [type]="'CONSOMMATION'"
                                              *ngIf="isRef"></suivi-energy>
                                <suivi-energy [report]="site.selectedReport" [type]="'ACHAT'"></suivi-energy>
                                <suivi-energy [report]="site.selectedReport" [type]="'PRODUCTION'"></suivi-energy>
                            </ng-container>
                            <div class="footnote">
                                * Les facteurs de pondération et d’émission CO2eq. utilisés dans ce rapport ont été
                                obtenus depuis le tableau 2 (page 33) et l’annexe
                                1
                                (page 41) de la
                                <a target="_blank"
                                   href="https://firebasestorage.googleapis.com/v0/b/optimise-enerplan.appspot.com/o/custom%2FDirective%20de%20lofen.pdf?alt=media&token=ca401540-0750-46ca-9084-b723ea946049">
                                    Directive de l’OFEN</a>. Pour le cas des réseaux de chaleur à distance (CAD), les
                                facteurs de pondération et d’émission CO2eq. ont
                                été
                                obtenus depuis les
                                <a target="_blank"
                                   href="https://firebasestorage.googleapis.com/v0/b/optimise-enerplan.appspot.com/o/custom%2FRapports%20de%20lofen.pdf?alt=media&token=9d3938fd-7653-45aa-aa4e-244297843397">
                                    Rapports de l’OFEN</a>. Pour le cas de l’électricité, les facteurs d’émission CO2eq.
                                ont été obtenus depuis la
                                <a target="_blank"
                                   href="https://firebasestorage.googleapis.com/v0/b/optimise-enerplan.appspot.com/o/custom%2FEmpfehlung_Oekobilanzdaten_im_Baubereich_2009_1_2016.pdf?alt=media&token=b9754159-c7f0-4f2a-a3ae-0d0fa00f9e81">
                                    liste de la KBOB.</a>
                            </div>

                            <suivi-submeters [energyStats]="energyStatsForCurrentYear"
                                             [report]="site.selectedReport" [reportRef]="site.selectedReportRef">
                            </suivi-submeters>
                        </div>
                    </mat-tab>
                    <mat-tab label="Efficacité énergétique" *ngIf="site.clientSite.gc">
                        <div #bloc class="bloc">
                            <h1>Efficacité énergétique</h1>
                            <p>Cette section contient les représentations graphiques de l'efficacité énergétique.</p>
                            <suivi-energy-efficiency [year]="selectedYear" [reportRef]="site.selectedReportRef"
                                                     [report]="site.selectedReport"
                                                     [goals]="goals"></suivi-energy-efficiency>
                        </div>
                    </mat-tab>
                    <mat-tab label="Objectifs et actions">
                        <div class="bloc">
                            <h1>Objectifs et actions</h1>
                            <mat-tab-group class="full-width tab-group">
                                <mat-tab label="Objectifs annuels" class="bloc bloc-actions">
                                    <div #bloc class="bloc">
                                        <suivi-goals [year]="site.selectedReport.year" [goals]="goals"
                                                     [reportRef]="site.selectedReportRef" [report]="site.selectedReport">
                                        </suivi-goals>
                                    </div>
                                </mat-tab>
                                <mat-tab label="Liste d'actions" class="bloc bloc-actions">
                                    <div #bloc class="bloc">
                                        <suivi-actions [year]="site.selectedReport.year" [goals]="goals"
                                                       [reportRef]="site.selectedReportRef" [report]="site.selectedReport">
                                        </suivi-actions>
                                    </div>
                                </mat-tab>
                            </mat-tab-group>
                        </div>


                    </mat-tab>
                    <mat-tab label="Facteurs d'influence">
                        <div #bloc class="bloc">
                            <h1>Facteurs d'influence</h1>
                            <p>Liste des facteurs d’influence et indicateurs pour expliquer les fluctuations de
                                l’efficacité énergétique.</p>
                            <suivi-influence-factors [year]="site.selectedReport.year"
                                                     [reportsOfSameConvention]="site.reportsIteratifs"
                                                     [report]="site.selectedReport"
                                                     [reportRef]="site.selectedReportRef"
                                                     (cb)="loadSuiviReports(site.selectedReport.uid)"
                            ></suivi-influence-factors>
                        </div>
                    </mat-tab>
                    <mat-tab label="Infos. complémentaires">
                        <div #bloc class="bloc">
                            <h1>Informations complémentaires</h1>
                            <suivi-documents [type]="''" [report]="site.selectedReport" [reportRef]="site.selectedReportRef"
                                             (cb)="cbFromChlid($event)">
                            </suivi-documents>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>

    <div class="overlay-drop-shadow" *ngIf="metas" fxLayout="column" fxLayoutAlign="start center">
        <mat-card class=" popup-fixed" fxFlex="95" style="width: 80%">
            <div fxLayout>
                <div fxFlex></div>
                <button mat-icon-button (click)="metas=null">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <ngx-extended-pdf-viewer
                    [height]="'99%'"
                    *ngIf="pdfSource"
                    [src]="pdfSource"
                    [textLayer]="true"
                    [zoom]="'page-width'"
                    [showHandToolButton]="true"
                    [enablePrint]="true"
                    useBrowserLocale="true">
            </ngx-extended-pdf-viewer>
        </mat-card>
    </div>
</div>
