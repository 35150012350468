import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import * as Highcharts from 'highcharts';
import PatternFill from "highcharts/modules/pattern-fill";
import Annotations from "highcharts/modules/annotations";
import {SiteService} from "../../shared/services/site.service";
import {BaseComponent} from "../../shared/BaseComponent";
import {AppService} from "../../shared/services/app.service";
import {
    AnnotationsOptions,
    Chart,
    ChartCallbackFunction, DashStyleValue,
    PatternObject,
    PatternOptionsObject,
    SeriesOptionsType,
    SeriesTooltipOptionsObject,
    XrangePointOptionsObject
} from "highcharts";
import {K} from "../../shared/models/K";
import {DomSanitizer} from "@angular/platform-browser";
import {H} from "../../shared/helpers/H";
import {Plot} from "../../shared/models/models";
import {PdmYearlyCalcedData} from "../../shared/models/PdmYearConfig";


if (typeof Highcharts === 'object') {
    PatternFill(Highcharts);
    //  Exporting(Highcharts);
    //  ExportData(Highcharts);
    Annotations(Highcharts);
    //  Debugger(Highcharts);
}

@Component({
    selector: 'statmens-bars',
    template: `
        <mat-card class=" " fxLayout="row">
            <highcharts-chart [Highcharts]="Highcharts"
                              [oneToOne]="true"
                              [options]="chartOptions"
                              (chartInstance)="onChartInstance($event)">
            </highcharts-chart>
            <div>

            </div>
        </mat-card>
    `,
    styleUrls: ['../../shared/components/shared-styles/shared.scss']
})
export class StatmensBarsComponent extends BaseComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {
    chart: Chart;
    errors: any[] = [];
    Highcharts: typeof Highcharts = Highcharts;
    chartOptions: Highcharts.Options;

    previousSerie = [];
    historyCount: number = 2;
    dataForYear = {};
    _mainUnit: string = "KWh";

    constructor(public myapp: AppService, public site: SiteService, public sanitizer: DomSanitizer) {
        super();
        this.site.siteSpecificEventTriggerer.subscribe(eventName => {

            if (eventName === SiteService.PDMS_YEARLY_PLOT_DATA_CALCED) {
                this.bootstrap();
            }
            if (eventName === SiteService.PDMS_YEARLY_CONFIG_CHANGED) {
                this.bootstrap();
            }
            if (eventName === SiteService.PDMS_YEARLY_CONFIG_SELECTED) {
                // this.bootstrap();
            }
        });
    }

    ngOnInit() {
        this.bootstrap();
    }

    bootstrap() {
        this.calcMaxHistory();
        this.populateData();
        this.initChart();
    }

    calcMaxHistory() {
        const maxCount = 5;
        this.historyCount = 0;
        for (let i = 0; i < maxCount; i++) {
            const yearOfCurrentSerie = this.site.year - i;
            if (this.site.availableYearsInRows.includes(yearOfCurrentSerie))
                this.historyCount++;
            else break;
        }
        // console.log('this.historyCount', this.historyCount, maxCount);
    }

    populateData() {
        for (let y = 0; y < this.historyCount; y++) {
            const yearOfCurrentSerie = this.site.year + y + 1 - this.historyCount;
            let data = [];
            this.site.selectedPdmYearlyPlotDataMap.forEach((item, i) => {
                if (item.releve_year === yearOfCurrentSerie)
                    data.push(this.getPointsForBars(item, i, y, 1));
            });
            data = K.months.map((month, i) => {
                if (data[i]) return data[i]; else return {x: i, y: 0, category: H.ucfirst(K.months[i]), custom: ""};
            });
            if (yearOfCurrentSerie === this.site.refYear)
                this.previousSerie = data;

            if (!this.site.availableYearsInRows.includes(yearOfCurrentSerie)) continue;
            if (this.chart && this.chart.series && this.chart.series[y])
                this.dataForYear[yearOfCurrentSerie] = data;
        }
    }

    getPointsForBars(item: PdmYearlyCalcedData, i, indexOfSerie: number, serieNum = 1) {
        const yearOfCurrentSerie = this.site.year + indexOfSerie + 1 - this.historyCount;
        // Adapt to unit
        const yVal = item.ve.corr / this.site.selectedPdm.fact_mult;
        if (this.site.year === yearOfCurrentSerie) {
            const evol = this.getEvolutiontoRefYear(item);
            return {
                x: item.releve_num, y: yVal, year: yearOfCurrentSerie, date: item.date,
                category: H.ucfirst(K.months[item.releve_num - 1]),
                custom: evol.percent, name: evol.delta
            };
        } else {
            return {
                x: item.releve_num, y: yVal, year: yearOfCurrentSerie, date: item.date,
                category: H.ucfirst(K.months[item.releve_num - 1]),
                custom: 0, name: 0
            };
        }

    }

    getEvolutiontoRefYear(item: PdmYearlyCalcedData) {
        const relKey = Number(H.getReleveKey(item.releve_year, item.releve_num));
        const relKeyOfRefYear = Number(H.getReleveKey(this.site.refYear, item.releve_num));
        const calcedItemOfRefYear = this.site.selectedPdmYearlyPlotDataMap.get(relKeyOfRefYear);
        let delta = 0, percent = 0;
        const yVal = item.ve.corr / this.site.selectedPdm.fact_mult;
        if (calcedItemOfRefYear) {
            const yValRefYear = calcedItemOfRefYear.ve.corr / this.site.selectedPdm.fact_mult;
            delta = yVal - yValRefYear;
            percent = 100 * (delta / yValRefYear);
        }
        // console.log("getCalcedDataItemOfRefYear", relKey, ' => ', calcedItemOfRefYear);
        return {delta, percent};
    }

    getSerie(serieIndex: number, serieGlobalIndex: number, showInLegend = true, serieNum = 1, serieTot = 1): any {
        let year = this.site.year - this.historyCount + serieIndex + 1;
        let assetIndex = this.historyCount - serieIndex - 1;
        if (serieIndex >= this.historyCount) {
            year -= this.historyCount;
            assetIndex += this.historyCount;
        }
        // console.log("getSerie", year + '_corr_' + serieNum + "-" + serieGlobalIndex);
        const s = {
            type: 'column',
            // animation: {
            //     duration: 600,
            //     easing: "in"
            // },
            shadow: false,
            color: {
                pattern: this.getPattern(assetIndex)
            },
            // index: serieGlobalIndex,
            // id: year + '_corr_' + serieNum + "-" + serieGlobalIndex,
            showInLegend,
            // borderWidth: assetIndex === 4 && this.historyCount === 5 ? 0.5 : 1.5,
            // borderColor: assetIndex === 4 && this.historyCount === 5 ? "#000000" : "#ffffff",
            name: year + " ",
            tooltip: {
                headerFormat: "{series.name}" + (year === this.site.refYear ? '®' : ''),
                pointFormat: '<br>{point.category}, {point.y:.2f} ' + this._mainUnit,
            },
            data: this.dataForYear[year],
            stack: year,
            // lineWidth: serieIndex === (this._series.length - 1) && this.plot.cumul ? 3 : 2
        };

        // deltas
        if (year === this.site.year && serieIndex < this.historyCount) {
            s['dataLabels'] = {
                useHTML: false,
                inside: false,
                enabled: true,
                rotation: s.type === "column" ? -90 : 0,
                align: "left",
                verticalAlign: "top",
                style: {
                    color: "#000000",
                    backgroundColor: "#ffff00",
                    fontWeight: 'normal'
                },
                // x: 15,
                y: -5,
                format: "®{point.name:,.1f} " + this._mainUnit + "; <b>{point.custom: .1f}%</b>",
            };
        } else {
            // s['dataLabels'] = {};
        }

        return s;
    }

    getPattern(index = 0, fill = null, bg = null, scale = 1): PatternOptionsObject {
        const paths = [
            "M0 0 L5 0 L5 5 L0 5 z",
            "M0 0 L5 0 L5 3 L0 3 z",
            "M0 0 L5 0 L5 2 L0 2 z",
            "M0 0 L3 0 L3 3 L0 3 z",
            "M0 5 L100 5 L-100 5 L5 5 L5 -100 L5 100 L5 5",
        ];
        const pattern = {
            color: '#000000',
            aspectRatio: 1,
            width: 5,
            height: 5,
            image: null,
            opacity: 1,
            path: null,
            x: 0,
            y: 0
        } as PatternOptionsObject;

        pattern.path = {
            d: paths[0],
            strokeWidth: 0,
            stroke: "#fff",
            r: 10,
        };
        pattern.color = K.chartColors[index];
        pattern.path.fill = fill ? fill : K.chartColors[index];
        pattern.backgroundColor = bg ? bg : '#FFFFFF';
        const rotates = ['rotate(0)', 'rotate(-45)', 'rotate(0)', 'rotate(45)', 'rotate(20)'];

        if (index === 0) {
            pattern.height = 3;
            pattern.path.d = paths[0];
            pattern.patternTransform = rotates[0];
        }
        if (index === 1) {
            pattern.height = 3;
            pattern.path.d = paths[2];
            pattern.patternTransform = 'rotate(-45)' + ",scale(1.2)";
        }
        if (index === 2) {
            pattern.height = 4;
            pattern.path.d = paths[1];
            pattern.patternTransform = rotates[index] + ",scale(1.2)";
        }
        if (index === 3) {
            pattern.height = 3;
            pattern.path.d = paths[2];
            pattern.patternTransform = rotates[index] + ",scale(1.2)";
        }
        if (index === 4) {
            pattern.x = 0;
            pattern.height = 4;
            pattern.width = 4;
            pattern.path.d = paths[3];
            pattern.patternTransform = "scale(" + scale + ")";
        }
        if (index === 5) {
            pattern.x = 0;
            pattern.height = 9;
            pattern.width = 9;
            pattern.y = 0;
            pattern.backgroundColor = "#ff0000";
            pattern.path.d = paths[4];
            pattern.path.strokeWidth = 1;
            pattern.path.stroke = "#ffffff";
            pattern.patternTransform = "scale(" + scale + ")";
        }

        // pattern.image='https://seeklogo.com/images/B/Burberry_pattern-logo-361C603AF3-seeklogo.com.png';
        // pattern.image = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOCIgaGVpZ2h0PSI0OSIgdmlld0JveD0iMCAwIDI4IDQ5Ij48ZyBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxnIGlkPSJoZXhhZ29ucyIgZmlsbD0iYmx1ZSIgZmlsbC1ydWxlPSJub256ZXJvIj48cGF0aCBkPSJNMTMuOTkgOS4yNWwxMyA3LjV2MTVsLTEzIDcuNUwxIDMxLjc1di0xNWwxMi45OS03LjV6TTMgMTcuOXYxMi43bDEwLjk5IDYuMzQgMTEtNi4zNVYxNy45bC0xMS02LjM0TDMgMTcuOXpNMCAxNWwxMi45OC03LjVWMGgtMnY2LjM1TDAgMTIuNjl2Mi4zem0wIDE4LjVMMTIuOTggNDF2OGgtMnYtNi44NUwwIDM1Ljgxdi0yLjN6TTE1IDB2Ny41TDI3Ljk5IDE1SDI4di0yLjMxaC0uMDFMMTcgNi4zNVYwaC0yem0wIDQ5di04bDEyLjk5LTcuNUgyOHYyLjMxaC0uMDFMMTcgNDIuMTVWNDloLTJ6Ii8+PC9nPjwvZz48L3N2Zz4=";
        // console.log("Pattern:" + index, pattern, isRef);
        return pattern;
    }

    initChart() {
        const categsCap = K.months.map(i => H.ucfirst(i));
        categsCap.unshift('');

        let series = [];
        for (let i = 0; i < this.historyCount; i++) {
            const ser = this.getSerie(i, series.length + 1, true, 1, 2);
            // console.log("serie() : ", i, ser);
            series.push(ser);
        }

        this.chartOptions = {
            accessibility: {
                "enabled": false
            },
            chart: {
                "borderColor": "#000000",
                "borderWidth": 0,
                "displayErrors": true,
                "height": 450,
                "marginRight": 100,
                "marginTop": 40,
                "plotBackgroundColor": "#ffffff",
                "plotBorderWidth": K.lineWidthBase,
                "plotShadow": false,
                "reflow": false,
                "style": {
                    "fontFamily": "Roboto",
                    "fontSize": "14px"
                },
                "type": "column",
                "width": 950
            },
            credits: {
                "enabled": false
            },
            exporting: {
                "enabled": false
            },
            lang: {
                "numericSymbols": null,
                "thousandsSep": "'"
            },
            legend: {
                "align": "right",
                "borderWidth": 0,
                "itemMarginBottom": 0,
                "itemMarginTop": 0,
                "itemStyle": {
                    "fontSize": "10px",
                    "fontWeight": "normal",
                    "lineHeight": "8px"
                },
                "layout": "vertical",
                "padding": 10,
                "squareSymbol": false,
                "symbolRadius": 0,
                "symbolWidth": 40,
                "verticalAlign": "middle"
            },
            plotOptions: {
                "column": {
                    "animation": false,
                    "borderColor": "#000000",
                    "borderWidth": 1,
                    "edgeWidth": 5,
                    "grouping": true,
                    "groupPadding": 0.05,
                    "shadow": false,
                    "stacking": "normal",
                    "visible": true
                }
            },
            series: series,
            title: {
                "text": undefined
            },
            xAxis: [
                {
                    "categories": categsCap,
                    "crosshair": true,
                    "labels": {
                        "style": {
                            "color": "#666666",
                            fontWeight: "Normal",
                            "fontSize": "12px"
                        }
                    },

                    "lineWidth": 0.5,
                    "lineColor": "#666666",
                    title: {
                        "text": "Mois"
                    },
                    "type": "category"
                }
            ],
            yAxis: {
                "lineColor": "#666666",
                "lineWidth": 0.5,
                title: {
                    "text": this.getYAxisLabel()
                },
            }
        };
    }

    getYAxisLabel() {
        if (this.site.selectedPdm.fact_mult === 1) return "KWh";
        if (this.site.selectedPdm.fact_mult === 1000) return "MWh";
        if (this.site.selectedPdm.fact_mult === 1000000) return "GWh";
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    ngOnDestroy() {
        // if (this.chart) Highcharts.charts.splice(this.chart.index, 1);
    }

    ngAfterViewInit(): void {
        // console.info('ngAfterViewInit():' + this.plot.plotType);
    }

    onChartInstance(chart: Highcharts.Chart) {
        this.chart = chart;
    }
}

//
// createSeries() {
//     if (this.site.selectedPdm.getIsHaveMultipleSeries()) {
//         for (let i = 0; i < this.historyCount * 2; i++) {
//             let SerieNum = 1;
//             if (i >= this.historyCount) SerieNum = 2;
//             const ser = this.getSerie(i, this.chartOptions.series.length + 1, true, SerieNum, 2);
//             this.chartOptions.series.push(ser);
//         }
//     }
// }