import * as MathJS from "mathjs";
import {PlotPoint} from "../models/PdmYearConfig";
import {adaptVisual} from "./visualAdapters";

export class Optimise {

    public static keyFromYearMonth(year: number, month: number): string {
        const monthStr = (month < 10 ? '0' : '') + month.toString();
        return year + monthStr;
    }

    public static arrayUnique(arr): any[] {
        return arr.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });
    }

    public static permute(permutation, length) {
        let result = [permutation.slice()],
            c = new Array(length).fill(0),
            i = 1, k, p;

        while (i < length) {
            if (c[i] < i) {
                k = i % 2 && c[i];
                p = permutation[i];
                permutation[i] = permutation[k];
                permutation[k] = p;
                ++c[i];
                i = 1;
                result.push(permutation.slice());
            } else {
                c[i] = 0;
                ++i;
            }
        }
        return result;
    }

    public static checkPrecision(precision) {
        if (precision < 0) {
            throw new Error('Invalid precision. Must be 0 or higher');
        }
    }

    /**
     * Round a value with precision
     */
    public static round(value, precision = 0) {
        if (value === null || typeof value === 'undefined') {
            return 0
        }
        Optimise.checkPrecision(precision)
        if (precision === 0) {
            return Math.round(value)
        }
        const exponential = Number(`${value}e${precision}`)
        const rounded = Math.round(exponential)
        return Number(`${rounded}e-${precision}`)
    }

    /**
     * Implementation of to Fixed that handle rounding issue
     * comparing to javascript native method (0.615).toFixed(2) === "0.61"
     * which should return a Number -> 0.62
     */
    public static toFixed(value, precision = 0) {
        return Optimise.round(value, precision).toFixed(precision)
    }

    public static subset(arra, arra_size) {
        let result_set = [],
            result;
        for (let x = 0; x <= Math.pow(2, arra.length); x++) {
            result = [];
            let i = arra.length - 1;
            do {
                if ((x & (1 << i)) !== 0) {
                    result.push(arra[i]);
                }
            } while (i--);

            if (result.length === arra_size) {
                const permutations = this.permute(result, 3);
                // console.log('permutations', permutations);
                permutations.forEach(ar => {
                    result_set.push(ar);
                });

                continue;
            }
        }

        result_set = result_set.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });
        return result_set;
    }

    public static comb(array, size) {
        const f = (part, start) => {
            let result = [], i, l, p;
            for (i = start, l = array.length; i < l; i++) {
                p = part.slice(0);                       // get a copy of part
                p.push(array[i]);                        // add the iterated element to p
                if (p.length < size) {                   // test if recursion can go on
                    result = result.concat(f(p, i + 1)); // call c again & concat rresult
                } else {
                    result.push(p);                      // push p to result, stop recursion
                }
            }
            return result;
        };

        return f([], 0);
    }

    public static sortObjectKeys(o) {
        let sorted = {},
            key, a = [];

        for (key in o) {
            if (o.hasOwnProperty(key)) {
                a.push(key);
            }
        }

        a.sort();

        for (key = 0; key < a.length; key++) {
            sorted[a[key]] = o[a[key]];
        }
        return sorted;
    }

    public static sortArrayByKeyVal(arr: any[], key: string) {
        return arr.sort((a, b) => {
            let x = a[key], y = b[key];
            if (typeof x === "string") {
                x = ("" + x).toLowerCase();
            }
            if (typeof y === "string") {
                y = ("" + y).toLowerCase();
            }

            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    public static filterOutliers(someArray) {
        let values = someArray.concat();

        // Then sort
        values.sort(function (a, b) {
            return a - b;
        });

        /* Then find a generous IQR. This is generous because if (values.length / 4)
         * is not an int, then really you should average the two elements on either
         * side to find q1.
         */
        let q1 = values[Math.floor((values.length / 4))];
        // Likewise for q3.
        let q3 = values[Math.ceil((values.length * (3 / 4)))];
        let iqr = q3 - q1;

        // Then find min and max values
        let maxValue = q3 + iqr * 1.5;
        let minValue = q1 - iqr * 1.5;

        return values.filter((x) => {
            return (x <= maxValue) && (x >= minValue);
        });
    }

    public static hashString(str: string) {
        var hash = 0, i, chr;
        if (str.length === 0) return hash;
        for (i = 0; i < str.length; i++) {
            chr = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    };

    public static mean(points: PlotPoint[]): PlotPoint {//S2m
        // console.log("Calc Mean", points);
        const xMean = MathJS.mean(points.map(p => p.x));
        const yMean = MathJS.mean(points.map(p => p.y));
        return new PlotPoint({x: xMean, y: yMean});
    }

    public static min(yVals: number[]): number {
        return MathJS.min(yVals);
    }

    public static max(yVals: number[]): number {
        return MathJS.max(yVals);
    }

    public static meanDeviation(points: PlotPoint[], mean: PlotPoint): number {//SSxx
        let deviationFromMean = [];
        points.forEach((pt, i) => {
            deviationFromMean.push(Math.abs(pt.y - mean.y));
        });
        return MathJS.mean(deviationFromMean);
    }

    public static ssxx(points: PlotPoint[], mean: PlotPoint): number {//SSxx
        let acc = 0;
        points.forEach((pt, i) => {
            acc += (pt.x - mean.x) * (pt.x - mean.x);
        });
        return acc;
    }

    public static ssxy(points: PlotPoint[], mean: PlotPoint): number {//SSxy
        let acc = 0;
        points.forEach((pt, i) => {
            acc += (pt.x - mean.x) * (pt.y - mean.y);
        });
        return acc;
    }

    public static ssyy(points: PlotPoint[], mean: PlotPoint): number {//Total sum of squares SSt=SSyy
        let acc = 0;
        points.forEach((pt, i) => {
            acc += (pt.y - mean.y) * (pt.y - mean.y);
        });
        return acc;
    }

    public static sse(points: PlotPoint[], slope: number, intercept: number): number {//Residual sum of squares of errors 􏰀􏰀􏰁
        let acc = 0;
        points.forEach((pt, i) => {
            const predictedY = pt.x * slope + intercept;
            const ecart = pt.y - predictedY;
            acc += ecart * ecart;
        });
        return acc;
    }

    public static fixRawGraphData(gr: any): any {
        const av = adaptVisual(gr);
        const {data, layout} = av;

        let rawVisualToUpload = {
            ...gr,
            plotData: {data, layout}
        };
        return rawVisualToUpload;
    }

    /*

     */

    // 10 * (10^(4/5) * eff^(1/10) - 10)
    public static makeTheoretical = target => {
        const E = target + 100;
        const R = 10 * (((10 ** (4 / 5)) * (E ** (1 / 10))) - 10);
        return x => {
            return 100 * ((1 + (R / 100)) ** x);
        };
    }

    // a + x^2 + 100
    public static makeMinimum = target => x => {
        const a = MathJS.bignumber(target).div(100)
        const y = a.times(MathJS.bignumber(x).pow(2)).plus(100);
        return Number(y);
    }

    public static generateGoals = (target) => {
        if (!target) {
            return {index: [], minimum: [], theoretical: []};
        }
        const x = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        const r = target - 100;
        const m = Optimise.makeMinimum(r);
        const t = Optimise.makeTheoretical(r);
        return {
            index: x,
            minimum: x.map(m),
            theoretical: x.map(t),
        };
    }
}
