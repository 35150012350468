/*
Actions
 */
import {H} from "../helpers/H";
import {Optimise} from "../helpers/Optimise";
import moment from "moment";
import {Observable, Subscription} from "rxjs";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {User} from "./models";
import {Moment} from "moment/moment";


export class UserSummary {
    email: string;
    firebaseId: string;
    firstName: string;
    lastName: string;
    office: string;
    uid: string;
}

export class SiteEvent {
    body: string;
    date_iso: string;
    date_str: string;
    site_name: string;
    subject: string;
    target: string[];
    ts: number;

    uid: string;
    uid_site: string;
    uid_user: string;
    user_metas: UserSummary;
    wat: string;
    related_data: any = {};
    _mom: Moment;
    _month: number;
    _day: string;
    _day_of_month: string;
    _dayNo: number;
    _year: number;
    _week: number;

    get tag() {
        if (this.wat === 'share-proto-hebdo') return 'Partage';
        if (this.wat.includes('ReportStatusChanged')) return this.wat.replace('ReportStatusChanged-', '');
    }

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
        if (this.ts) {
            this._mom = moment.unix(this.ts);
            this._month = Number(this._mom.format('MM'));
            this._day = this._mom.format('dddd');
            this._day_of_month = this._mom.format('D');
            this._dayNo = this._mom.day();
            this._year = this._mom.year();
            this._week = this._mom.week();
        }
    }
}

export class Notif {
    key: string;
    route: string;
    type: string;
    site: string;
    siteName: string;
    year: number;
    week: number;
    position: number;
    ts: number;
    targetUID: string;
    label: string;
    sender: { uid: string, name: string };
    _mom: Moment;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
        this._mom = moment.unix(this.ts / 1000);
    }
}
