import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {AppService} from "../../../shared/services/app.service";
import {ApiService} from "../../../shared/services/api.service";
import {Overlay} from "@angular/cdk/overlay";
import {SiteService} from "../../../shared/services/site.service";
import {LayoutService} from "../../../shared/services/core/layout.service";
import {DomSanitizer} from "@angular/platform-browser";
import {from, Subscription} from "rxjs";
import {concatMap} from "rxjs/operators";
import {H} from "../../../shared/helpers/H";
import {Plot} from "../../../shared/models/models";
import {K} from "../../../shared/models/K";
import {egretAnimations} from "../../../shared/animations/egret-animations";
import {MatSidenav} from "@angular/material/sidenav";
import * as Highcharts from 'highcharts';
import {AnnotationsOptions, Chart} from "highcharts";
import Annotations from "highcharts/modules/annotations";
import PatternFill from "highcharts/modules/pattern-fill";
import {OptimiseChart} from "../../../shared/helpers/OptimiseChart";
import {PdmYearConfig} from "../../../shared/models/PdmYearConfig";
import {PdmDataRowExported} from "../../../shared/models/PdmDataRow";
// import firebase from "firebase/compat/app";
// import FieldValue = firebase.firestore.FieldValue;

if (typeof Highcharts === 'object') {
    PatternFill(Highcharts);
    //  Exporting(Highcharts);
    //  ExportData(Highcharts);
    Annotations(Highcharts);
    //  Debugger(Highcharts);
}

@Component({
    selector: 'app-mensuel',
    templateUrl: './mensuel.component.html',
    styleUrls: ['./mensuel.component.scss'],
    animations: egretAnimations
})
// extends ProtoHebdo
export class MensuelComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('sidenav') sidenav: MatSidenav;

    chart: Chart;
    Highcharts: typeof Highcharts = Highcharts;
    public chartOptions: Highcharts.Options = {...K.chartOptions};
    updateFlag: boolean = false;
    withCategories: boolean = false;

    availableYears = [];
    selectedYear: number = 0;
    selectedMonth: number = 0;

    public sideNavMode = '';
    groupRowsByValue: string = "fluid";
    replyText: string = "";

    noteEditMode = false;
    noteText: string = '';
    noteKey: string = null;

    annotationText: string = "";
    annotationFilter: string = "ALL";
    commentReplyMode: boolean = false;
    commentSavingInProgress: boolean = false;

    annotationSavingInProgress: boolean = false;
    annotationPriority = 'NORMAL';

    report_comments: any[] = [];
    annotationTemp: AnnotationsOptions = {};

    commentCountTotalPerPdmAndMonth = {};
    commentCountPendingPerPdmAndMonth = {};
    commentCountTotalPerMonth = {};
    commentCountPendingPerMonth = {};
    commentSubscription: Subscription;

    selectedKey: string;
    optimiseChart: OptimiseChart;
    historyCount = 3;
    plotsDataFromCache: any;
    keyToPlotMap: Map<string, Plot> = new Map<string, Plot>();

    colWidth = ['200px', '160px', '130px', '100px', '100px', '100px', '100px'];
    months = K.months;
    mapYearsToFinalData: Map<string, PdmYearConfig[]> = new Map<string, PdmYearConfig[]>();
    selectedFinalPdmsDataArray: PdmYearConfig[] = [];
    selectedYearlyPdmConfig: PdmYearConfig;
    lastPlotSpotRaw: Highcharts.Point = null;
    lastPlotSpot: PlotSpot = null;

    constructor(public myapp: AppService, public api: ApiService,
                public overlay: Overlay, public site: SiteService,
                public viewContainerRef: ViewContainerRef,
                public layout: LayoutService, public sanitizer: DomSanitizer) {
        this.overlay = overlay;
        this.viewContainerRef = viewContainerRef;
    }

    get plotKeys(): string[] {
        if (this.plotsDataFromCache) {
            const arr = Array.from(Object.keys(this.plotsDataFromCache)).filter(key => this.keyToPlotMap.has(key));
            arr.sort();
            return arr;
        } else return [];
    }

    get commentsForSelectedStatus() {
        if (this.annotationFilter === "ALL")
            return this.commentsAllForMonth;
        else
            return this.commentsAllForMonth.filter(item => item.status === this.annotationFilter);
    }

    get commentsAllForMonth() {
        return this.commentsAllForCurrentYear.filter(item => item.id3 === this.selectedMonth);
    }

    get commentsAllForCurrentYear() {
        if (!this.report_comments) this.report_comments = [];
        return this.report_comments.filter(item => item.id2 === this.selectedYear);
    }

    boostrap() {
        this.availableYears = [];
        this.mapYearsToFinalData = new Map<string, PdmYearConfig[]>();
        /*
        this.site.sitePdmsYearlyConfigMap.forEach((finalDataFoYear, key) => {
            this.availableYears.push(Number(finalDataFoYear.year));
            let stored = [];
            if (this.mapYearsToFinalData.has(finalDataFoYear.year.toString())) {
                stored = this.mapYearsToFinalData.get(finalDataFoYear.year.toString());
            }
            stored.push(finalDataFoYear);
            this.mapYearsToFinalData.set(finalDataFoYear.year.toString(), stored);
        });
        */
        this.availableYears = this.availableYears.filter((v, i, a) => a.indexOf(v) === i);
        this.availableYears = this.availableYears.sort((a, b) => b - a);

        /*
                this.statMensDataItemMap = new Map<string, StatMensDataItem>();
              /* Array.from(this.selectedStatMensData.data.values()).forEach(item => {
                     this.statMensDataItemMap.set(item.fluid + "_" + item.uid_place, item);
                 });*/
        this.calcCommentsStatsForCurrYear();
        this.generatePlotConfigs();
    }

    saveNoteForMonth() {
        /*
        this.site.api.saveStatMensNote(this.site.uid, this.selectedMonth + 1, this.selectedYear, this.noteText)
            .subscribe(resp => {
                if (resp.status === 1) {
                    //this.site.clientSite.bmens_notes = resp.body;
                    this.noteEditMode = false;
                    this.selectMonthIndex(this.selectedMonth);//Refresh
                }
                // console.log("addNoteForMonth()", resp);
            });

         */
    }

    selectMonthIndex(m) {
        this.selectedMonth = m;
        const month = Number(this.selectedMonth) + 1;
        const prefix = month < 10 ? '0' : '';
        /*
        this.noteKey = prefix + month + "" + this.selectedYear;
        if (this.site.clientSite.bmens_notes && this.site.clientSite.bmens_notes[this.noteKey]) {
            this.noteText = this.site.clientSite.bmens_notes[this.noteKey];
            this.noteText = Buffer.Buffer.from(this.noteText, "base64").toString();
        } else this.noteText = '';*/
    }

    selectYear(y) {
        this.selectedYear = y;
        if (this.selectedMonth === 0) this.selectMonthIndex(0);
        //this.site.selectReportYearAndInitPdmYearlyConfig(y);
        this.selectedFinalPdmsDataArray = this.mapYearsToFinalData.get(y.toString());
        this.calcCommentsStatsForCurrYear();
    }

    loadComments(reload = false) {
        // this.commentSubscription = this.myapp.db.collection('Comments')
        //     .valueChanges().subscribe(comments => {
        //         // console.log("loadComments", comments);
        //         this.report_comments = comments;
        //     }, error => {
        //         console.error("loadComments from firestore failed", error);
        //     });
    }

    calcCommentsStatsForCurrYear() {
        this.commentCountTotalPerMonth = {};
        this.commentCountPendingPerMonth = {};
        this.commentCountTotalPerPdmAndMonth = {};
        this.commentCountPendingPerPdmAndMonth = {};
        //  return;
        this.commentsAllForCurrentYear.forEach(com => {
            const pdmConcatMonthPdm = com['id4'] + com['id3'];
            const month = String(com['id3']);

            if (!this.commentCountTotalPerMonth[month]) this.commentCountTotalPerMonth[month] = 1;
            else this.commentCountTotalPerMonth[month] = this.commentCountTotalPerMonth[month] + 1;

            console.log("calcCommentsStatsForCurrYear", pdmConcatMonthPdm, com['status'], month);

            if (!this.commentCountTotalPerPdmAndMonth[pdmConcatMonthPdm]) this.commentCountTotalPerPdmAndMonth[pdmConcatMonthPdm] = 1;
            else this.commentCountTotalPerPdmAndMonth[pdmConcatMonthPdm] = this.commentCountTotalPerPdmAndMonth[pdmConcatMonthPdm] + 1;

            if (com['status'] === 'PENDING') {

                if (!this.commentCountPendingPerPdmAndMonth[pdmConcatMonthPdm]) this.commentCountPendingPerPdmAndMonth[pdmConcatMonthPdm] = 1;
                else this.commentCountPendingPerPdmAndMonth[pdmConcatMonthPdm] = this.commentCountPendingPerPdmAndMonth[pdmConcatMonthPdm] + 1;

                if (!this.commentCountPendingPerMonth[month]) this.commentCountPendingPerMonth[month] = 1;
                else this.commentCountPendingPerMonth[month] = this.commentCountPendingPerMonth[month] + 1;
            }
        });
        console.log("calcCommentsStatsForCurrYear", this.commentCountTotalPerMonth, this.commentCountPendingPerMonth);
    }

    cleanCommentText() {
        let text = this.annotationText.toString().replaceAll("\n", '<br>');
        text = H.wordWrap(text, 50, '<br>');
        this.annotationTemp = {
            id: 'tempComment',
            labels: [{
                point: {
                    x: this.lastPlotSpot ? this.lastPlotSpot.x : undefined,
                    y: this.lastPlotSpot ? this.lastPlotSpot.y : undefined,
                    xAxis: 0,
                    yAxis: 0
                },
                text: text,
                borderColor: "#000000"
            }],
            visible: true,
            zIndex: 1000
        } as AnnotationsOptions;

        if (this.chart) {
            this.chart.removeAnnotation('tempComment');
            this.chart.addAnnotation(this.annotationTemp);
        }

    }

    setCommentStatus(uid: string, status: boolean) {
        // this.updateComment(uid, {status: status ? 'COMPLETED' : 'PENDING'}).then(done => {
        //     console.log("Comment set as finished");
        //     this.calcCommentsStatsForCurrYear();
        // });
    }

    sendReply(uid) {
        this.commentSavingInProgress = true;
        const commObj = {
            message: this.replyText,
            name: this.myapp.user_display_name,
            userId: this.myapp.user.uid,
            ts: H.unixTs(false)
        };
        console.error("Send reply not saving")
        // this.updateComment(uid, {replies: FieldValue.arrayUnion(commObj)}).then(done => {
        //     console.log("Done saving comment", commObj);
        //     this.commentSavingInProgress = false;
        // });
    }

    insertComment() {
        const uid = "bm-" + H.randomInt(10000000, 999999999999);
        this.annotationTemp.id = uid;

        let commentText = "";
        if (this.annotationTemp && this.annotationTemp.labels && this.annotationTemp.labels[0])
            commentText = this.annotationTemp.labels[0].text;

        const commObj = {
            comment: commentText,
            display_name: this.myapp.user_display_name,
            id1: this.selectedYearlyPdmConfig.fluid,
            id2: this.selectedYear,
            id3: this.selectedMonth,
            id4: this.selectedYearlyPdmConfig.uid_pdm,
            plot_key: this.selectedKey !== undefined ? this.selectedKey : null,
            plot_data: JSON.stringify(this.annotationTemp),
            priority: this.annotationPriority,
            status: 'PENDING',
            title: "Month :" + this.selectedMonth,
            ts_created: H.unixTs(true),
            type: "bilan-mensuel",
            uid: uid,
            uid_site: this.site.clientSite.uid,
            uid_user: this.myapp.user.uid,
            replies: [],
        };
        this.annotationSavingInProgress = true;
        // this.myapp.db.collection('Comments').doc(commObj.uid).set(commObj).then(done => {
        //     console.log("Done saving comment", commObj);
        //     this.calcCommentsStatsForCurrYear();
        //     this.sideNavMode = 'comment';
        //     this.annotationSavingInProgress = false;
        // });
    }

    updateComment(uid: string, updateCommand: any) {
        // return this.myapp.db.collection('Comments')
        //     .doc(uid)
        //     .update(updateCommand);
    }

    showAnnotationOnChart() {
        this.commentsForSelectedStatus
            .filter(c => c.plot_key === this.selectedKey)
            .forEach(comm => {
                if (comm.plot_data) {
                    const ann = JSON.parse(comm.plot_data) as AnnotationsOptions;
                    this.chart.removeAnnotation(ann.id);
                    this.chart.addAnnotation(ann);
                }
            });
    }

    groupRowsBy(groupByParam) {
        this.groupRowsByValue = groupByParam;
    }

    doFromRow(ev) {
        const {action, dataItem} = ev;
        console.log("doFromRow", action, dataItem);
        this.selectedYearlyPdmConfig = dataItem;
        this.annotationTemp = {};
        if (action === 'show') {
            // if (this.selectedRowData && this.selectedRowData.cache_path)
            this.loadPlotsFromCache();
            this.loadComments();
        }
        if (action === 'addComment') {

        }
    }

    togleCommentMode() {
        this.sideNavMode = "addAnnotation";
        this.sidenav.toggle(true);
    }

    toggleSideBar() {
        this.sideNavMode = "comment";
        this.annotationFilter = 'PENDING';
        this.sidenav.toggle(true);
    }

    loadPlotsFromCache() {
        this.optimiseChart = null;
        const cache = this.site.uid + "." + this.selectedYearlyPdmConfig.uid_pdm + "." + this.selectedYear + ".raws";
        this.site.api.getStatMensPlotsCache(cache).subscribe(res => {
            if (res.status === 1) {
                this.plotsDataFromCache = JSON.parse(res.body);
                Object.keys(this.plotsDataFromCache).forEach(key => {
                    // console.log("loadPlotsFromCache:FORREACH: ", key, this.plotsDataFromCache[key]);
                    if (key !== "METAS")
                        this.plotsDataFromCache[key] = ApiService.jsonStrFromB64(this.plotsDataFromCache[key]);
                });
            } else {
                this.plotsDataFromCache = null;
                this.myapp.showError(res.error);
            }

            //console.log("loadPlotsFromCache Final:", cache, this.plotsDataFromCache);
            // this.loadDataIntoPlot(this.plotKeys[0]);

            // console.log("loadPlotsFromCache", Object.keys(this.plotsDataFromCache));
        });
    }

    loadDataIntoPlot(key) {
        this.generatePlotConfigs();
        this.selectedKey = key;
        this.optimiseChart = new OptimiseChart(
            this.keyToPlotMap.get(key),
            this.site.selectedPdm,
            this.site.dataSummaryMap,
            OptimiseChart.SHOW_IN_RAPPORT_MENS);

        const chartWithCategs = [];
        if ((key.includes('QUALITY') || key.includes('ANNUAL')) && !key.includes('CUMUL')) this.withCategories = true;
        else this.withCategories = false;

        this.chartOptions = this.optimiseChart.getChartOptions();
        this.chartOptions.chart.events = {};
        this.chartOptions.plotOptions = {column: {}, line: {}, bar: {}, scatter: {}};
        this.chartOptions.plotOptions.line = {events: {}};
        this.chartOptions.plotOptions.line.events.click = (ev) => this.fromChart(ev, 'line');

        this.chartOptions.plotOptions.column = {events: {}};
        this.chartOptions.plotOptions.column.events.click = (ev) => this.fromChart(ev, 'bar');

        this.chartOptions.plotOptions.scatter = {events: {}};
        this.chartOptions.plotOptions.scatter.events.click = (ev) => this.fromChart(ev, 'scatter');


        console.log("loadDataIntoPlot: " + key, this.plotsDataFromCache, this.plotsDataFromCache[key], this.chartOptions);
        const series = (this.plotsDataFromCache[key]);

        this.chartOptions.series = series;
        // console.log("loadPlotsFromCache", this.chartOptions, series);

        if (this.chart) {
            this.chart.update(this.chartOptions, true, false);
            this.chart.redraw();
            this.updateFlag = true;
        } else {
            console.log("loadPlotsFromCache: CHART NULL", series);
        }
    }

    generatePlotConfigs() {
        const plSign = new Plot(this.selectedYear, this.historyCount, 'scatter', Plot.SECTION_SIGNATURE, 'CONS', false, true, PdmDataRowExported.VE1_CONS, this.site.selectedMonthIndex);
        this.keyToPlotMap.set(plSign.getKey(), plSign);

        const plQuality = new Plot(this.selectedYear, this.historyCount, 'line', Plot.SECTION_QUALITY, 'CONS', false, false, PdmDataRowExported.VE1_CONS, this.site.selectedMonthIndex);
        const plDernier = new Plot(this.selectedYear, this.historyCount, 'line', Plot.SECTION_DERNIERE, 'CONS', true, false, PdmDataRowExported.VE1_CONS, this.site.selectedMonthIndex);

        this.keyToPlotMap.set(plQuality.getKey(), plQuality);
        this.keyToPlotMap.set(plDernier.getKey(), plDernier);
        const sections = ['VE1_D', "PT_C", "PT_D", "REAC_C", "REAC_D"];
        sections.forEach(section => {

            const plAnnual = new Plot(this.selectedYear, this.historyCount, 'column', Plot.SECTION_ANNUAL, 'CONS', false, true, section.toLowerCase());
            const plBars = new Plot(this.selectedYear, this.historyCount, 'column', Plot.SECTION_BASIC, 'COST', false, false, section.toLowerCase(), this.site.selectedMonthIndex);
            const plCumul = new Plot(this.selectedYear, this.historyCount, 'line', Plot.SECTION_BASIC, 'COST', true, false, section.toLowerCase(), this.site.selectedMonthIndex);
            this.keyToPlotMap.set(plAnnual.getKey(), plAnnual);
            this.keyToPlotMap.set(plBars.getKey(), plBars);
            this.keyToPlotMap.set(plCumul.getKey(), plCumul);

        });
    }

    /*



    Overrided
     */

    onChartInstance(chart) {
        //  console.log('onChartInstance:' + this.plot.plotType, chart);
        this.chart = chart;
    }

    fromChart(ev, type) {
        const {point} = ev;

        this.lastPlotSpotRaw = {...point};
        this.lastPlotSpot = new PlotSpot(this.lastPlotSpotRaw);

        console.log("FromChart: " + type, point, this.lastPlotSpot);
        this.sideNavMode = "addAnnotation";
        this.sidenav.toggle(true);

    }

    ngOnInit() {
        this.boostrap();
        this.site.siteSpecificEventTriggerer.subscribe(eventName => {
            this.boostrap();
        });
        this.loadComments();
        /*
        this.layout.layoutConf$.subscribe(conf => {
            setTimeout(() => {
                //     this.detectClientDimensions();
                //     this.resetFullScreen();
                this.scanStoredData();
            }, 500);
        });
*/

    }

    sideNavListener(e) {
        //  this.detectClientDimensions();
        // this.resetFullScreen();
    }

    ngAfterViewInit() {
        this.layout.publishLayoutChange({sidebarStyle: 'closed'});
        //  this.detectClientDimensions();
    }

    ngOnDestroy() {
        if (this.commentSubscription) this.commentSubscription.unsubscribe();
        this.report_comments = null;
    }
}

export class PlotSpot {
    category: any = null;
    date: string = null;
    id: string = null;
    x: number = null;
    y: number = null;
    year: number = null;
    custom: any = null;
    clientX: number = null;
    clientY: number = null;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            if (this.hasOwnProperty(key))
                this[key] = dynProps[key];
        });
    }
}
