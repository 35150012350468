import {Injectable} from '@angular/core';
import {BehaviorSubject, EMPTY, Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {User} from '../models/models';
import {Router} from "@angular/router";
import {Subscription} from "rxjs/internal/Subscription";
import * as Sentry from "@sentry/browser";
import {H} from "../helpers/H";
import {environment} from "../../../environments/environment";
import {initializeApp} from "firebase/app";
import {getAuth, onAuthStateChanged, signInWithEmailAndPassword} from "firebase/auth";
import {getFirestore, collection, doc, getDoc} from "firebase/firestore";

// import {initializeApp} from "@angular/fire/app";

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public state: string;
    public fbUser: any;
    public user: User;
    public userId: string;
    public userEmail: string;
    public userDisplayName: string;
    public error: string;
    public authSubject: BehaviorSubject<string>;
    public userSubs: Subscription;
    public tracesLog: any[] = [];

    constructor(public router: Router) {

        const app = initializeApp(environment.firebase);
        this.authSubject = new BehaviorSubject<string>('');
        getAuth().languageCode = 'fr';
        onAuthStateChanged(getAuth(), (u) => {
                this.fbUser = u;
                if (u !== null) {
                    this.addTrace('this.afAuth.authState subs: u not null: ' + u.uid);
                    this.userId = u.uid;
                    this.loadUserFromFirestore(u);
                } else {
                    this.addTrace('this.afAuth.authState subs: u IS NULL: ');
                    this.user = null;
                    this.userId = null;
                    this.userEmail = null;
                    this.userDisplayName = null;
                    if (this.userSubs) {
                        this.userSubs.unsubscribe();
                        this.userSubs = null;
                    }
                    this.authSubject.next("notConnected");
                }
            }
        );
        this.addTrace('Auth service constructor');
    }

    async loadUserFromFirestore(u) {
        const afs = getFirestore();
        const collectionRef = collection(afs, "users");
        const docRef = doc(afs, "users", u.uid);
        const docSnap = await getDoc(docRef);
        const user = docSnap.data();
        if (user === null || user === undefined) {
            this.addTrace('loadUserFromFirestore: user null or undefined ');
        } else {
            this.addTrace('loadUserFromFirestore: user returned successfully ');
        }
        // console.log("loadUserFromFirestore", user, this.user);
        if (!this.user) {
            this.user = new User(user);
            this.authSubject.next("connected");
            this.addTrace('loadUserFromFirestore: authSubject-(CONNECTED) ');
        } else {
            this.user = new User(user);
            this.authSubject.next("updated");
            this.addTrace('loadUserFromFirestore: authSubject-next(UPDATED) ');
        }
    }

    doLogin(value) {
        return new Promise<any>((resolve, reject) => {
            getAuth().languageCode = 'fr';
            signInWithEmailAndPassword(getAuth(), value.email, value.password)
                .catch(err => {
                    this.userEmail = value.email;
                    this.error = err;
                    this.addTrace('sign-in: ERROR: ' + err);
                    this.reportError("doLogin() " + this.userEmail + ' - ' + err);
                    // console.log("Error login", err);
                    this.authSubject.next("error");
                    reject(err);
                })
                .then(res => {
                    resolve(res);
                }, err => reject(err));
        });
    }

    doLogout() {
        return new Promise((resolve, reject) => {
            getAuth().signOut().then(r => {
                if (this.userSubs)
                    this.userSubs.unsubscribe();
                localStorage.removeItem('currentUser');
                localStorage.removeItem('EGRET_USER');
                localStorage.removeItem('email');
                resolve(r);
            }, err => reject(err));
        });
    }

    addTrace(wat: string) {
        this.tracesLog.push({ts: H.unixTs(), wat});
    }

    reportError(trace: string) {
        if (this.userId)
            trace = this.userId + ": " + trace;
        console.log(this.tracesLog);
        Sentry.captureException(new Error("ErrorLogin" + trace), {
            tags: {
                userId: this.userId,
                email: this.userEmail
                // fbUser: this.fbUser,
                // trace: this.tracesLog,
            },
        });
    }

}
