<div class="flex-container full-width" fxLayout="row">
    <div fxFlex="450px" class="pr-8" fxLayout="column">
        <div class="fixed-container">
            <mat-card class="p-4 m-4">
                <mat-card-title>
                    <span >Points de mesure</span>
                </mat-card-title>
                <mat-card-content fxLayout="column" fxLayoutAlign="start stretch" class="flex-container p-0">
                    <div class="p-4" fxFlex="25px">
                        <div fxLayout="row">
                            <button mat-raised-button color="primary"
                                    [ngClass]="{active:site.selectedFluid===fl}"
                                    fxFlex="24" class="btn-xs-25 mr-4"
                                    (click)="site.selectFluid(fl) "
                                    *ngFor="let fl of myapp.k.fluids">{{ fl }}
                            </button>
                        </div>
                    </div>
                    <mat-list class="full-width mb-32 compact-list" fxFlex="80">
                        <ng-container *ngFor="let pdm of site.pdms">
                            <mat-list-item class="p-0 full-width"
                                           *ngIf="pdm.fluid===site.selectedFluid"
                                           [ngClass]="{active:site.selectedPdm&&pdm.uid===site.selectedPdm.uid}"
                                           (dblclick)="site.populatePdmCache(true,false)"
                                           (click)="selectPdm(pdm)">
                                <div fxLayout="row" class="p-0 full-width">
                                    <icon-fluid style="width: 25px" [fluid]="pdm.fluid"></icon-fluid>
                                    <div fxLayout="row" fxFlex="100">
                                        <h6 class="m-0 ml-8" fxFlex="70">{{ pdm.label }}<br>
                                            <span class="text-muted"><b>{{ pdm.pdm_prefix }}</b> | {{ pdm.label_place }} | {{ pdm.xls }}</span>
                                        </h6>
                                        <span fxFlex></span>
                                        <mat-chip class="p-4 text-small">{{ pdm.status }}</mat-chip>
                                        <mat-icon inline="true" *ngIf="pdm.is_vpdm" class="icon-vpdm">bluetooth_connected</mat-icon>
                                    </div>
                                </div>

                            </mat-list-item>
                        </ng-container>

                    </mat-list>

                </mat-card-content>
            </mat-card>

            <mat-card *ngIf="site.selectedPdm" class="p-8 m-4" fxLayoutAlign="start center" fxLayout="row wrap">
                <button [ngClass]="{active:y===site.year}" *ngFor="let y of site.availableYearsInRows"
                        (click)="site.selectReportYearAndInitPdmYearlyConfig(y)"
                        mat-raised-button color="primary" class="mr-4 mb-4 btn-xs btn-left-icon shadowed"
                >
                    {{ y }}
                </button>

            </mat-card>

            <mat-card class="p-8 m-4" *ngIf="site.selectedPdm&&site.year">
                <h4>Année de réf pour {{ site.year }} pour <b>{{ site.selectedPdm.label_short }}</b></h4>
                <mat-form-field class="full-width">
                    <mat-select placeholder="Année de réference" [value]="site.refYear">
                        <mat-option (click)="saveInPdmRefYearForSelectedYear(y)"
                                    [value]="y"
                                    *ngFor="let y of [site.year-1,site.year-2,site.year-3,site.year-4]">{{ y }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="p-0">
                    <button color="primary" mat-raised-button [ngClass]="{active:o===historyCount}" class="mr-4 btn-xs-25"
                            (click)="historyCount=o;rePlot()"
                            *ngFor="let o of [1,2,3,4,5]">{{ o }}
                    </button>
                </div>

            </mat-card>

            <mat-card class="p-4 m-4" *ngIf="site.selectedPdm.yearly_config&&site.selectedPdm.yearly_config_map.has(site.year)">
                <h4>Choix du mois </h4>
                <div class="p-0">
                    <button color="primary" mat-raised-button [ngClass]="{active:site.selectedMonthIndex===m}"
                            class="m-4 btn-xs-25" (click)="site.selectedMonthIndex=m;scanReleveGenSummary()"
                            *ngFor="let m of [0,1,2,3,4,5,6,7,8,9,10,11]">{{ monthsLabels[m] |uppercase }}
                    </button>
                </div>

            </mat-card>
        </div>


    </div>
    <!----
    CHARTS
    -->
    <div fxFlex class="pb-32" *ngIf="site.year&&site.selectedPdm&&dispMode==='config'">
        <mat-card class="m-8 mb-8 p-4 mt-4">
            <div fxLayout="row">
                <button mat-raised-button color="primary" class="mr-4" [ngClass]="{active:selectedSection===s}"
                        *ngFor="let s of sections" (click)="rePlot(s)">{{ s }}
                </button>
                <button mat-raised-button color="primary" class="mr-4" [ngClass]="{active:selectedSection==='SIGNATURE'}"
                        (click)="rePlot('SIGNATURE')">Signature
                </button>
                <button mat-raised-button color="primary" class="mr-4" [ngClass]="{active:selectedSection==='MODELE'}" (click)="rePlot('MODELE')">
                    Modèle
                </button>
                <div fxFlex (dblclick)="site.test()">{{ selectedSection }} {{ site.year }}</div>

                <a mat-raised-button color="warn" class="mr-4"
                   [@animate]="{value:'*',params:{delay:'800ms',y:'50px'}}"
                   *ngIf="previewPdfExists"
                   href="{{api.basePrintUrl}}save-statmens-preview?path={{previewPath}}&do=1"
                   target="_blank">
                    <mat-icon>print</mat-icon>
                </a>

                <button mat-raised-button color="warn"
                        *ngIf="site.selectedPdm&&site.year" (click)="savePlotsCaheToServer()">Generer PDF
                </button>
                <a routerLink="/pdm-editor" mat-raised-button color="primary" class="ml-8 mr-4">
                    <mat-icon>table_view</mat-icon>
                    Tableur
                </a>
            </div>
        </mat-card>

        <div fxLayout="column">

            <ng-container *ngIf="selectedSection!=='SIGNATURE'&&selectedSection!=='MODELE'">
                <div fxLayout="row" *ngIf="plotAnnual">

                    <statmens-plot (debug)="formCallBack($event)"
                                   [plotId]="'plotAnnual'"
                                   (save)="updateLocalCache($event)"
                                   [plot]="plotAnnual"
                                   [updated]="plotNoiseForceUpdate">
                    </statmens-plot>
                    <div>
                        <!--
                        <ngx-json-viewer [json]="debugIncoming['plotAnnual']" [expanded]="false" style="font-size: 10px"></ngx-json-viewer>
                        -->
                    </div>
                </div>
                <div fxLayout="row" *ngIf="plot1">
                    <statmens-plot (debug)="formCallBack($event)"
                                   (save)="updateLocalCache($event)"
                                   [plotId]="'plot1'"
                                   [plot]="plot1"
                                   [updated]="plotNoiseForceUpdate">
                    </statmens-plot>
                    <div>
                        <!--
                       <ngx-json-viewer [json]="debugIncoming['plot1']" [expanded]="false" style="font-size: 10px"></ngx-json-viewer>
                         -->
                    </div>
                </div>
                <div fxLayout="row" *ngIf="plot2">
                    <statmens-plot (debug)="formCallBack($event)"
                                   (save)="updateLocalCache($event)"
                                   [plotId]="'plot2'"
                                   [plot]="plot2"
                                   [updated]="plotNoiseForceUpdate">
                    </statmens-plot>
                    <div>
                        <!--
                        <ngx-json-viewer [json]="debugIncoming['plot2']" [expanded]="false" style="font-size: 10px"></ngx-json-viewer>
                          -->
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="selectedSection==='SIGNATURE'">
                <div fxLayout="row" *ngIf="plot1&&site.selectedPdm">
                    <statmens-plot (debug)="formCallBack($event)"
                                   (save)="updateLocalCache($event)"
                                   [plot]="plot1"
                                   [updated]="plotNoiseForceUpdate">
                    </statmens-plot>
                    <div>
                        <statmens-table [plot]="plot1" [forceUpdate]="plotNoiseForceUpdate"></statmens-table>
                    </div>
                </div>

                <div fxLayout="row" *ngIf="plot2&&site.selectedPdm">
                    <statmens-cassure *ngIf="plot2&&site.selectedPdm"
                                      [year]="site.year"
                                      [pdm]="site.selectedPdm"
                                      [plot]="plot2">
                    </statmens-cassure>
                    <div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="selectedSection==='MODELE'">
                <div fxLayout="row" *ngIf="plot1&&site.selectedPdm">
                    <statmens-plot (debug)="formCallBack($event)"
                                   (save)="updateLocalCache($event)"
                                   [plot]="plot1"
                                   [updated]="plotNoiseForceUpdate">
                    </statmens-plot>
                    <div>
                        <statmens-table [plot]="plot1" [forceUpdate]="plotNoiseForceUpdate"></statmens-table>
                    </div>
                </div>

                <div fxLayout="row" *ngIf="plot2&&site.selectedPdm">
                    <statmens-plot (debug)="formCallBack($event)"
                                   (save)="updateLocalCache($event)"
                                   [plot]="plot2"
                                   [updated]="plotNoiseForceUpdate">
                    </statmens-plot>
                    <div>
                        <statmens-table [plot]="plot2" [forceUpdate]="plotNoiseForceUpdate"></statmens-table>
                    </div>
                </div>
            </ng-container>


        </div>
    </div>

    <div fxFlex="200px" style="background: #ccc">
        <button (click)="errMessages=[]">X</button>
        <mat-card class="p-4" *ngFor="let mess of errMessages">{{ mess }}</mat-card>
    </div>

</div>
