import {H} from "../helpers/H";
import {PdmDataRowExported} from "./PdmDataRow";
import moment, {Moment} from "moment";
import {K} from "./K";
import {Observable} from "rxjs";
import {getStorage, ref, getDownloadURL, getMetadata, StorageReference} from "firebase/storage";
// import {getStorage, ref, getDownloadURL, getMetadata, StorageReference} from "@angular/fire/storage";
import {EnergyStats, Metric} from "./EnergyStats.model";
import {BmensGroup} from "./Bmens.model";
// import firebase from "firebase/compat";
// import FullMetadata = firebase.storage.FullMetadata;

export class ObsWrapper {
    resp: any;
    type: string;
}

export class NavLink {
    state: string;// compatibility with IMenuItem
    link: string;
    icon: string;
    label: string;
}

export class Resp {
    ts: string;
    ts_init: string;
    body: any;
    chapters: any[];
    payload: string;
    ret_vars: object;
    error: string;
    status: number;
    log: string;
}

export class User {
    uid: string;
    uid_bdd: string;

    email: string;
    favs: string[];
    first_name: string;
    gender: string;
    groups: string[];
    last_name: string;
    office: string;
    role: string;
    group: string;// new : ENERPLAN, CLIENT, CANTON, AUTRE

    infos: {
        npa_loc?: string;
        address?: string;
        company?: string;
        tel_fixed?: string;
        tel_mobile?: string;
    };

    invited: boolean;
    inviteToken: string;
    ts_invited: number;

    site_access: Map<string, SiteAccess> = new Map<string, SiteAccess>();
    _momInvited: Moment;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
        if (!this.infos) this.infos = {};

        if (this.site_access) {
            const _map: Map<string, SiteAccess> = new Map<string, SiteAccess>();
            Object.keys(this.site_access).forEach(k => {
                _map.set(k, new SiteAccess(this.site_access[k]));
            });
            this.site_access = _map;
        }
        this._momInvited = moment.unix(this.ts_invited / 1000);
    }

    get isAdminOrSuperUser() {
        return this.isAdmin || this.isSuperUser;
    }

    get isAdmin() {
        const userRole = K.Roles[this.role];
        return (userRole === K.Roles.ADMIN);
    }

    get isSuperUser() {
        const userRole = K.Roles[this.role];
        return (userRole === K.Roles.SUPERUSER);
    }

    get sites() {
        if (!this.site_access)
            return [];
        // fix bug where map key exist but value is null: repopulate uid_site from object key
        Array.from(this.site_access.keys()).forEach(uidSite => {
            this.site_access.get(uidSite).uid_site = uidSite;
        });
        return Array.from(this.site_access.values())
            .sort((a1, a2) => {
                const arg1 = (a1.site_name ? a1.site_name : '');
                const arg2 = (a2.site_name ? a2.site_name : '');
                return arg1.localeCompare(arg2);
            })
    }

    get metas(): UserMini {
        return {
            group: this.group,
            email: this.email,
            firebaseId: this.uid,
            firstName: this.first_name,
            lastName: this.last_name,
            office: this.office,
            role: this.role,
            gender: this.gender,
            uid: this.uid_bdd
        };
    }

    filterNavLinks(links: any[]) {// filter menus
        return links.filter(it => this.canSeeUrl('/' + it.state));
    }

    canSeeUrl(url) { // used to redirect if not have rights to access
        if (url.includes('/proto-hebdo')) url = '/proto-hebdo';
        if (url.includes('/bmens')) url = '/bmens';
        if (url.includes('/suivi')) url = '/suivi';
        const selectedSiteUID = localStorage.getItem('selectedDomainUID');//
        const selectedDomainRef = localStorage.getItem('selectedDomainRef');//
        const isAccessibleByConfig = this.isAdminOrSuperUser || this.accessibleRoutesForSite(selectedSiteUID).includes(K.routesConstants[url]);//
        const isAccessibleBecauseAdmin = this.isAdmin && K.routesForAdmin.includes(url);
        const isAccessibleBecauseSuperUser = this.isSuperUser && K.routesForSuperUser.includes(url);
        const final = isAccessibleByConfig || isAccessibleBecauseAdmin || isAccessibleBecauseSuperUser;
        /*
        console.log("canSeeUrl", url, {
            selectedDomainRef, isAccessibleByConfig,
            isAccessibleBecauseSuperUser, isAccessibleBecauseAdmin, final
        });
        */
        return final;// || isAccessibleBecauseAdmin || isAccessibleBecauseSuperUser;
    }

    accessibleRoutesForSite(uidSite): string[] { // routes accessible by config for regular user
        let accessTags: string[];
        if (this.site_access.has(uidSite)) accessTags = this.site_access.get(uidSite).rights;
        if (accessTags)
            accessTags = accessTags
                .filter(it => it.includes('@VIEW'))
                .map(it => it.replaceAll('@VIEW', ''));
        else accessTags = [];
        // accessTags.push("DASHBOARD");
        return accessTags;
    }

    getRightsForSiteAndRoute(route): string[] { // rights for selectedsite by config for regular user
        const selectedSiteUID = localStorage.getItem('selectedDomainUID');//  this.isAdminOrSuperUser ||
        let accessTags: string[];
        if (this.site_access.has(selectedSiteUID)) accessTags = this.site_access.get(selectedSiteUID).rights;
        if (accessTags)
            accessTags = accessTags
                .filter(it => it.includes(route))
                .map(it => it.replaceAll(route + '@', ''));
        else accessTags = [];
        // accessTags.push("DASHBOARD");
        return accessTags;
    }

    fullName(reverseOrder = false, html = false) {
        let lastName = this.last_name;
        if (html) lastName = ' <b>' + this.last_name + '</b> ';
        if (reverseOrder)
            return this.gender + lastName + ' ' + this.first_name;
        else
            return this.gender + ' ' + this.first_name + ' ' + lastName;
    }

}

export class SiteAccess {
    responsability: string;
    following: number = 1;
    uid_site: string;
    site_name: string;
    rights: string[] = [];

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
    }

    get responsabilityLevel() {
        return K.domainResponsability[this.responsability];
    }

    get responsabilityLabel() {
        return K.displayDomainResponsability[this.responsabilityLevel];
    }
}

export class UserMini {
    uid: string;//mongodb _id
    firebaseId: string;
    email: string;
    firstName: string;
    gender?: string;
    lastName: string;
    role?: string;
    office: string;
    group: string;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
    }
}

export class DomainReportPage {
    key: string;
    level: string;
    position: number;
    title: string;
    shape: any;
    rawVisualsUrl: string;
    comments_total: number = 0;
    comments_pending: number = 0;
    title_chunks = [];
    title_last: string;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
        this.title_chunks = this.title.split(" - ").map(s => s.trim());
        this.title_last = this.title_chunks[this.title_chunks.length - 2] + ' - ' + this.title_chunks[this.title_chunks.length - 1];
        this.comments_total = 0;
        this.comments_pending = 0;
    }
}

export class BatimentTemp {
    uid: string;
    batID: number;
    batName: string;
    bdName: string;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
        this.uid = (this.bdName.trim() + this.batName.trim() + this.batID).toLowerCase();
    }
}

export class EpClient {
    uid: string;
    address: string;
    sites_count: number;
    stats: any;
    lang: string;
    name: string;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
    }
}

export class ClientSite {
    address: string;
    canton: string;
    gps: string;
    name: string;
    npaLoc: string;
    ref: string;
    street: string;
    optimization: any = {};
    uid: string;
    uid_client: string;
    client_name: string;
    map_url: string;
    statmens_prefix: string;
    notes: string;

    features: any;
    gc: number;
    type_suivi: string;

    releve_delays: SiteReleveDelay[] = [];
    releve_day: string;//mon,tue,wed,thu,fri.sat,sun

    ep_db_name: string;
    ep_tab_name: string;
    ep_bat_name: string;
    ep_bat_id: number;

    energy_usage: any;
    energy_purchase: any;
    energy_production: any;

    usage_map: Map<string, SiteTariffData> = new Map<string, SiteTariffData>();
    purchase_map: Map<string, SiteTariffData> = new Map<string, SiteTariffData>();
    production_map: Map<string, SiteTariffData> = new Map<string, SiteTariffData>();

    year_tariff_default: number;
    year_tariff_display: number;

    tariff_config: SiteTariffConfig[] = [];
    tariff_config_map: Map<string, SiteTariffConfig> = new Map<string, SiteTariffConfig>();

    old_energy_stats: any;
    old_energy_stats_map: Map<string, EnergyStats> = new Map<string, EnergyStats>();
    old_default_energystats: EnergyStats;
    old_energystat_to_display: EnergyStats;


    bmens_groups: BmensGroup[];
    places: any;
    weather: string;
    vars_extra: any = {};
    done = 0;
    ts: number;
    ts_created: number;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);


        /// TODO:: Remove this
        this.old_energy_stats_map = new Map<string, EnergyStats>();
        if (this.old_energy_stats) {
            // const cache = JSON.parse(JSON.stringify(this.old_energy_stats));
            Object.keys(this.old_energy_stats).forEach(year =>
                this.old_energy_stats_map.set(year, new EnergyStats(this.old_energy_stats[year])));


            this.old_energy_stats_map.forEach((v, k) => {
                if (v.year === this.year_tariff_default) {
                    this.old_energystat_to_display = v;
                    this.old_default_energystats = v;
                }
            });
            this.old_energy_stats_map.forEach((v, k) => {
                if (v.year === this.year_tariff_display)
                    this.old_energystat_to_display = v;
            });

            this.done++;
        }
        /*


        END REMOVE
         */
        if (!this.year_tariff_display) this.year_tariff_display = this.year_tariff_default;

        if (this.bmens_groups && this.bmens_groups.length) {
            this.bmens_groups = this.bmens_groups.sort((a, b) => {
                return a.disp_order - b.disp_order;
            });
        }

        // populate energy maps
        if (this.energy_usage) {
            this.usage_map = new Map<string, SiteTariffData>();
            Object.keys(this.energy_usage).forEach(key => {
                const tarifData = new SiteTariffData(this.energy_usage[key]);
                this.usage_map.set(key, tarifData);
                const tarifConfig = this.tariff_config_map.get(tarifData.uid_tarif);

            });
        }
        if (this.energy_purchase) {
            this.purchase_map = new Map<string, SiteTariffData>();
            Object.keys(this.energy_purchase).forEach(key => {
                const tarifData = new SiteTariffData(this.energy_purchase[key]);
                this.purchase_map.set(key, tarifData);
            });
        }
        if (this.energy_production) {
            this.production_map = new Map<string, SiteTariffData>();
            Object.keys(this.energy_production).forEach(key => {
                const tarifData = new SiteTariffData(this.energy_production[key]);
                this.production_map.set(key, tarifData);
            });
        }

        // populate tariffs configs and fill gaps in energy data according to year start/end
        if (this.tariff_config && Array.isArray(this.tariff_config)) {
            this.tariff_config = this.tariff_config.map(it => {
                const tc = new SiteTariffConfig(it);
                this.tariff_config_map.set(tc.uid, tc);
                for (let y = tc.year_start; y <= tc.year_end; y++) {
                    const key = y + '-' + tc.uid;
                    if (!this.usage_map.has(key)) {
                        const newTarifData = new SiteTariffData({});
                        newTarifData.year = y;
                        newTarifData.uid_tarif = tc.uid;
                        newTarifData.uid = key;
                        newTarifData.price = 0;
                        newTarifData.energy = 0;
                        newTarifData.unit = tc.unit;
                        newTarifData.fp = K.EnergyAgentsFactors[tc.agent];
                        this.usage_map.set(key, newTarifData);
                    }
                    if (!this.purchase_map.has(key)) {
                        const newTarifData = new SiteTariffData({});
                        newTarifData.year = y;
                        newTarifData.uid_tarif = tc.uid;
                        newTarifData.uid = key;
                        newTarifData.price = 0;
                        newTarifData.energy = 0;
                        newTarifData.unit = tc.unit;
                        newTarifData.fp = null;
                        newTarifData.ceges = null;
                        this.purchase_map.set(key, newTarifData);
                    }
                    if (!this.production_map.has(key) && tc.is_prod === 1) {
                        //console.log("Client site CONS", tc.is_prod, key);
                        const newTarifData = new SiteTariffData({});
                        newTarifData.year = y;
                        newTarifData.uid_tarif = tc.uid;
                        newTarifData.uid = key;
                        newTarifData.unit = tc.unit;
                        newTarifData.energy = 0;
                        newTarifData.prod = 0;
                        newTarifData.efficiency = 0;
                        newTarifData.excedentaire = 0;
                        newTarifData.arg = 0;
                        this.production_map.set(key, newTarifData);
                    }

                }
                return tc;
            }).sort((a, b) => a.disp_order - b.disp_order);
        }

        if (this.map_url) {
            const rootChunk = 'https://map.geo.admin.ch/embed.html';
            const chunks = this.map_url.split('?');
            if (chunks.length > 1) {
                this.map_url = rootChunk + '?' + chunks[1];
            }
        }

        //console.log('ClientSiteClientSite',dynProps, this.energy_usage, this.usage_map, this.tariff_config);
    }

    get typeSuiviStr() {
        return K.TypeEngagementLabel[this.type_suivi];
    }

    get strForFiltering() {
        return (this.ref + this.name + this.address + this.street).toLowerCase();
    }

    getPlaceById(id: string) {
        return this.places && this.places[id] ? this.places[id].label : '';
    }
}

export class SiteTariffData {
    uid: string;//uid_tarif+year
    uid_tarif: string;
    year: number;
    energy: number;
    cost: number;
    price: number;
    fp: number;
    ceges: number;
    ts: number;
    unit: string;//inherited from tarif, thats why it's readonly after creation

    // production related
    prod: number;
    efficiency: number;
    excedentaire: number;
    arg: number;// pour indice: M2 ou autre
    _evol: number;//<-- populated in runtime for evolution in suivi
    _tariff: SiteTariffConfig;//<-- cache, populated in runtime

    constructor(dynProps: any = {}) {
        Object.assign(this, dynProps);
    }

    get unitStr() {
        return Metric.unitShort[this.unit];
    }

    get cegesEmitted(): number {
        return this.ceges * this.energy * 1e-9;
    }


    get energyAdapted() {
        if (this.unit === K.Units.VOLUME)
            return this.energy;
        else
            return this.energy / 1e6;
    }

    set energyAdapted(v) {
        if (this.unit === K.Units.VOLUME)
            this.energy = v;
        else
            this.energy = v * 1e6;

    }

    get priceAdapted() {
        if (!this.price) return 3.6;
        if (this.unit === K.Units.VOLUME)
            return Number(this.price.toFixed(2));
        else
            return Number((this.price * 1e5).toFixed(2));
    }

    set priceAdapted(v: number) {
        if (this.unit === K.Units.VOLUME)
            this.price = Number(v.toFixed(2));
        else
            this.price = Number(v / 1e5);

    }

    getCegesEmittedForInput(econ_energy: number): number {
        return this.ceges * econ_energy * 1e-9;
    }
}

export class SiteTariffConfig {
    uid: string = '';
    fluid: string = '';
    agent: string = '';
    lieu: string = '';
    label: string = '';
    unit: string = '';
    disp_order: number = 0;
    year_start: number = 0;
    year_end: number = 0;
    ts: number = 0;
    is_prod = 0;
    hidden = 0;
    uid_meter: string; // new bmens meter related to same data source
    _mom: Moment;

    get unitStr() {
        return K.unitShort[this.unit];
    }

    get priceUnitStr() {
        return K.getDefaultCostUnits(this.unit);
    }

    get isWater() {
        return this.fluid === 'EAU';
    }

    constructor(dynProps: any = {}) {
        Object.assign(this, dynProps)
        this._mom = moment.unix(this.ts);
    }

    renderAmount(amount: number) {
        if (this.unit === 'energy') return {value: amount / 1e6, unit: Metric.unitShort[this.unit]};
        if (this.unit === 'power') return {value: amount / 1e6, unit: Metric.unitShort[this.unit]};
        if (this.unit === 'petrol') return {value: amount, unit: Metric.unitShort[this.unit]};
        if (this.unit === 'volume') return {value: amount, unit: Metric.unitShort[this.unit]};
        if (this.unit === 'weight') return {value: amount / 1e6, unit: Metric.unitShort[this.unit]};
        if (this.unit === 'no_unit') return {value: amount / 1e6, unit: Metric.unitShort[this.unit]};
    }
}

export class SiteReleveDelay {
    id: string;
    year: number;
    num: number;
    date: string;
    edited = 0;

    public static getIdFromIndex(year, monthIndex): string {
        const releveNum = Number(monthIndex) + 1;
        let releveNumStr = releveNum.toString();
        if (releveNum < 10) releveNumStr = "0" + releveNum;
        return year + "" + releveNumStr;
    }

    public static getIdFromReleveNum(year, releveNum): string {
        if (releveNum < 10) releveNum = "0" + releveNum;
        return year + "" + releveNum;
    }

    constructor(year: number, num: number, date: string, edited = 0) {
        this.year = year;
        this.num = num;
        this.date = date;
        this.edited = edited;
        this.id = SiteReleveDelay.getIdFromReleveNum(this.year, this.num);
    }
}

export class SitePlace {
    uid: string;
    label: string;
}

export class StorageDocumentMetas {
    type: string;
    bucket: string;
    generation: number;
    metageneration: number;
    fullPath: string;
    name: string;
    size: number;
    timeCreated: string;
    updated: string;
    md5Hash: string;
    contentDisposition: string;
    contentEncoding: string;
    contentType: string;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
        this.generation = Number(this.generation);
        this.metageneration = Number(this.metageneration);
    }
}

export class StorageDocument {
    epDocUID: string;
    type: string;
    full_path: string;
    chapter: string;
    section: string;
    title: string;
    uid: string;
    uid_user: string;
    storage_metas: StorageDocumentMetas;//populated by angular from firebaseStorage
    user_metas: UserMini;
    uid_domain: string;
    mime_type: string;
    ts_file_created: string;
    ts: number;

    removed: number;

    file_creation_date: string;
    _fullPath: string;
    _storageMetaSubscription: Promise<any>;
    _storageFileSubscription: Promise<string>;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
        if (this.user_metas) this.user_metas = new UserMini(this.user_metas);
        if (this.storage_metas) this.storage_metas = new StorageDocumentMetas(this.storage_metas);
        if (this.ts_file_created) {
            this.file_creation_date = moment.unix(Number(this.ts_file_created) / 1000).format("DD/MM/YYYY HH:mm");
        }
    }

    get dateStr() {
        if (this.storage_metas && this.storage_metas.generation) {
            return moment.unix(Number(this.storage_metas.generation) / 1000000).format("DD/MM/YYYY HH:mm");
        } else return '';
    }

    get dateAddedStr() {
        return moment.unix(Number(this.ts)).format("DD/MM/YYYY HH:mm");
    }

    genObs() {
        const storage = getStorage();
        const docRef = ref(storage, this.full_path);
        this._storageMetaSubscription = getMetadata(docRef);
        this._storageFileSubscription = getDownloadURL(docRef);
    }
}

export class ReportAnnotationPoint {
    name: string;
    text: string;
    pointIndex: number;
    x: number;
    y: number;
    xMin: number;
    xMax: number;
    yMin: number;
    yMax: number;
    xTitle: string;
    yTitle: string;
    color: string;
    dateStamp: string;

    //  const {x, y, name, xMin, xMax, yMin, yMax, xTitle, yTitle, pointIndex} = this.selectedPointForAnnotation;
    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
    }

    get json() {
        return {...this};
    }
}

export class ReportAnnotation {
    _x: string;
    _y: string;
    x: number;
    y: number;
    text: string;
    xref: string;
    yref: string;
    ax: number;
    ay: number;
    align: string;

    showarrow: boolean;
    arrowhead: number;
    arrowsize: number;
    arrowwidth: number;
    arrowcolor: string;
    startarrowhead: number;

    font: {
        size: number;
        color: string;
    };
    bordercolor: string;
    borderwidth: number;
    borderpad: number;
    bgcolor: string;
    point_cache?: ReportAnnotationPoint;
    uid: string;
    report_date: string;
    visual_key: string;
    uid_comment: string;
    priority: string;
    initial_text: string;
    is_global: boolean;
    ts_comment: number;
    ts: number;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
        if (this.priority === 'MEDIUM') this.priority = 'NORMAL';
        this.adaptTextBeforeDisplay();
    }

    adaptTextBeforeDisplay() {
        if (!this.initial_text) this.initial_text = this.text;
        if (!this.initial_text) this.initial_text = '';
        let annText = this.initial_text.replaceAll('Entrez un texte', '')
            .replaceAll("\n", '<br>')
            .replaceAll("  ", ' ')
            .replaceAll("  ", ' ')
            .replaceAll("  ", ' ');
        // .replaceAll("<br>", ' ');

        const commentDate = H.ucfirst(moment.unix(this.ts).format("ddd DD.MM.YYYY HH:mm"));
        if (!annText.includes('#dateAdded')) {
            if (annText.length > 50) annText = H.wordWrap(annText, 50, '<br>');
            // this.text = '<b #dateAdded>' + commentDate + '</b><br>' + annText;
            this.text = annText;
        }
    }

    styling(selectedcomment: Comment = null) {
        const styleObj = {
            align: 'left',
            arrowsize: 1,
            arrowwidth: 2,
            arrowcolor: '#ff6666',
            font: {size: 14, color: '#000000'},
            bordercolor: 'rgba(90,90,90,0.6)',
            borderwidth: 1,
            borderpad: 2,
            bgcolor: '#fafafa',
        };

        const annotationPriority = this.priority || 'NORMAL';
        styleObj['borderwidth'] = 1;
        if (annotationPriority === 'NORMAL') {
            styleObj['bordercolor'] = '#ff0000';
            styleObj['arrowcolor'] = '#ff0000';
            styleObj['bgcolor'] = '#ffffff';
            styleObj['font']['color'] = '#ff2a2a';
        }

        if (annotationPriority === 'INFO') {
            styleObj['bordercolor'] = '#008000';
            styleObj['arrowcolor'] = '#008000';
            styleObj['bgcolor'] = '#ffffff';
            styleObj['font']['color'] = '#008000';
        }

        if (annotationPriority === 'HIGH') {
            styleObj['bordercolor'] = '#ff0000';
            styleObj['arrowcolor'] = '#ff0000';
            styleObj['bgcolor'] = '#ffff00';
            styleObj['font']['color'] = '#ff0000';
        }
        if (selectedcomment && this.uid_comment === selectedcomment.uid) {
            styleObj['borderwidth'] = 3;
        }


        this.showarrow = !this.is_global;
        Object.assign(this, styleObj);
        this.adaptTextBeforeDisplay();
    }
}

export class CommentReply {
    uid: string;
    message: string;
    name: string;
    userId: string;
    timestamp: number;
    ts: number;

    constructor(dynProps: any = {}, uid: string = null) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
        if (uid) this.uid = uid;
        if (!this.timestamp && this.ts) this.timestamp = Number(this.ts * 1000);
    }
}

export class Comment {
    uid: string;
    uid_site: string;
    uid_user: string;
    display_name: string;
    num: number;
    type: string;
    year: number;
    title: string;
    comment: string;
    key_page: string;
    key_report: string;
    priority: string;
    priority_int: number;
    replies: any = null;
    reply_to: null;
    seen_log: null;
    status: string;
    annotations: any;
    annotationsMap: Map<string, ReportAnnotation> = new Map<string, ReportAnnotation>();
    date_created: string;
    date_updated: string;
    ts_created: number;
    ts_updated: number;
    task_done_ts: number;
    task_started_ts: number;

    page_position: number;// <-- populated after load
    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
        const annMap: Map<string, ReportAnnotation> = new Map<string, ReportAnnotation>;
        if (!this.uid) {//new
            this.uid = "com-" + H.randomStr(30);
            this.ts_created = H.unixTs();
            this.priority = "NORMAL";
            this.status = "PROPOSAL";
            this.year = moment().year();
        }
        if (this.priority === 'INFO')
            this.priority_int = 1;
        if (this.priority === 'NORMAL')
            this.priority_int = 2;
        if (this.priority === 'MEDIUM')
            this.priority_int = 3;
        if (this.priority === 'HIGH')
            this.priority_int = 4;
        if (this.display_name) {
            this.display_name = this.display_name.replace("Monsieur", 'M.');
            this.display_name = this.display_name.replace("Madame", 'Mme.');
        }
        if (this.page_position) this.page_position = Number(this.page_position);
        if (this.annotations) {
            Object.keys(this.annotations).forEach(k => {
                annMap.set(k, new ReportAnnotation(this.annotations[k]));
            });
            this.annotationsMap = annMap;
        }
    }

    get finalComment() {
        if (this.comment) return this.comment;
        else {
            let retVal = [];
            this.annotationsMap.forEach((v, k) => {
                // console.log('finalComment', this.uid, v.uid_comment, v.initial_text)
                if (v.initial_text)
                    retVal.push(v.initial_text);
            });
            return retVal.join(" | ");
        }
    }

    get fullCommentText() {
        if (this.comment) return this.comment;
        else {
            let retVal = [];
            this.annotationsMap.forEach((v, k) => {
                // console.log('finalComment', this.uid, v.uid_comment, v.initial_text)
                if (v.initial_text)
                    retVal.push(v.initial_text);
            });
            return retVal.join("<br>");
        }
    }

    get repliesItems(): any[] {
        if (!this.replies) return [];
        if (this.replies['none']) delete this.replies['none'];
        const reps = Object.keys(this.replies).map(uid => {
                if (this.replies && this.replies[uid]) {
                    return new CommentReply(this.replies[uid], uid);
                } else return {timestamp: 0};
            }
        ).sort((a, b) => a.timestamp - b.timestamp);
        //return Object.keys(this.replies);
        return reps;
    }
}

export class Plot {
    public static SECTION_ANNUAL = "ANNUAL";
    public static SECTION_BASIC = "BASIC";
    public static SECTION_SIGNATURE = "SIGNATURE";
    public static SECTION_SIGNATURE_FINDER = "SIGNATURE_FINDER";
    public static SECTION_QUALITY = "QUALITY";
    public static SECTION_DERNIERE = "DERNIERE";
    public plotUnit: PlotUnit;
    id: string;
    section: string;
    plotType: string;
    cumul: boolean;
    correct: boolean;
    consOrCost: string;//cost,cons
    valKey: string;
    field: string;//cost,cons
    yearReport: number;
    yearCountHistory: number;
    seriesData = {};
    usedVarExpl = {};

    monthNumber: number;
    public debug = {};

    constructor(yearReport: number, yearCount: number, plotType: string, section: string, consOrCost: string,
                cumul: boolean,
                correct: boolean, valKey = PdmDataRowExported.VE1_CONS,
                monthNumber: number = 12) {
        this.yearReport = yearReport;
        this.yearCountHistory = yearCount;
        this.plotType = plotType;
        this.valKey = valKey;
        this.section = section;
        this.cumul = cumul;
        this.correct = correct;
        this.consOrCost = consOrCost;
        this.monthNumber = monthNumber;
        const isMonths = this.haveCategs() ? "-months" : "";
        this.id = section + "_" + cumul + "_" + plotType + isMonths;
        this.plotUnit = new PlotUnit(this.getKey(), 'ELEC');
    }

    isMain() {
        return this.valKey.toUpperCase().includes("VE1_");
    }

    getUnitFactor() {
        if (this.isCost()) return 1;
        else return this.plotUnit ? this.plotUnit.unit_multip : 1;
    }

    isCost() {
        return this.valKey.toUpperCase().includes("_C");
    }

    isPointe() {
        return this.valKey.toUpperCase().includes("PT_");
    }

    isReactive() {
        return this.valKey.toUpperCase().includes("ER_");
    }

    haveCategs() {
        if (this.section === Plot.SECTION_QUALITY) return true;
        if (this.plotType === 'column') return true;
        return false;
    }

    getKey(): string {
        let sectionKey = this.section;
        if (this.section === "BASIC") {
            sectionKey += "_" + this.valKey.toUpperCase();
            if (this.cumul)
                sectionKey += "_CUMUL";
        }
        return sectionKey;
    }
}

export class AppConfig {
    yearStartMeteo = 2010;
    maintenance = false;
}

export class Sniffer {
    reg_link: string;
    mandat: string;
    bd_name: string;
    mon: string;
    wed: string;
    month_end: string;
    hebdo: string;
    daily: string;
    date_start: string;
    date_end: string;
    ts: number;

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => {
            this[key] = dynProps[key];
        });
    }
}

/// TODO: Mark for removal
export class PlotUnit {
    fluid: string;
    key: string;
    unit_releves: string;
    unit_plot: string;
    unit_multip: number;
    ts: number = 0;

    constructor(keyPlot: string, fluid: string) {
        this.ts = 0;
        this.key = keyPlot;
        this.fluid = fluid;
        this.unit_releves = this.fluid === "EAU" ? 'm³' : "K";
        this.unit_plot = this.fluid === "EAU" ? 'm³' : "M";
        this.unit_multip = this.fluid === "EAU" ? 1 : 0.001;
    }

    setUnit(newValue: string) {
        this.unit_plot = newValue;
        if (newValue === "K") this.unit_multip = 1;
        if (newValue === "M") this.unit_multip = 0.001;
        if (newValue === "G") this.unit_multip = 0.000001;
        this.ts = H.unixTs();
    }
}

