import {Component, OnInit, OnDestroy} from "@angular/core";
import {Observable, Subscription} from "rxjs";
import {AppService} from '../../shared/services/app.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {ApiService} from "../../shared/services/api.service";
import {LayoutService} from "../../shared/services/core/layout.service";
import {SiteService} from "../../shared/services/site.service";
import {egretAnimations} from "../../shared/animations/egret-animations";
import {ClientSite} from "../../shared/models/models";


@Component({
    selector: "app-site-select",
    templateUrl: "./site-select.component.html",
    styleUrls: ["./site-select.component.scss"],
    animations: egretAnimations
})
export class SiteSelectComponent implements OnInit, OnDestroy {
    searchTermSub: Subscription;
    initVal: string = '';

    clientsSites: ClientSite[];
    clientSitesObs: Observable<any[]>;
    consoleLines = [];

    constructor(
        public myapp: AppService,
        public api: ApiService,
        public site: SiteService,
        public http: HttpClient,
        public router: Router,
        public layout: LayoutService,
    ) {
        this.myapp.isDisplayedComponentAdmin = false;

    }

    ngOnInit() {
        const dis = this;
        this.layout.publishLayoutChange({sidebarStyle: 'closed'});
        console.log('SiteSelectComponent::ngOnInit', this.myapp.user);
        setTimeout(function () {
            dis.refresh(new Event('change'));
        }, 600);
        // this.myapp.storeCurrentRoute();
    }


    ngOnDestroy() {
        if (this.searchTermSub) {
            this.searchTermSub.unsubscribe();
        }
    }

    refresh($event) {
        const key = $event.target ? $event.target.value : '';
        this.api.listSites(key).subscribe(res => {
            if (res && Array.isArray(res.body))
                this.clientsSites = res.body.map(it => new ClientSite(it));
            else {
                this.myapp.showError("Une erreur est survenue lors du chargement des bâtiments.");
            }
        }, error => {
            console.error('listSites:Error', error);
        });
    }

    selectDomain(siteUID) {
        console.log('selectDomain', siteUID);
        this.site.selectSite(siteUID);
        this.site.bootstrap();
        //  this.site.getSites(site.ref);
    }

    selectDomainEvt(site) {
        console.log('selectDomainEvt', site);
        this.selectDomain(site.uid);
    }
}
